.ParolUnut_1_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.forget-password-wrapper {
  display: inline-block;
}

.ParolUnut_1_block .ParolUnut_1 a {
  text-decoration: none;
}
.login-courses_sec_btn{
    padding: 0!important;
}

.ParolUnut_1_block .ParolUnut_1 h4 {
  color: #757575;
  font-size: 25px;
  text-align: center;
}

.ParolUnut_1_block .ParolUnut_1 p {
  color: #757575;
  margin-left: 10px;
  font-size: 18px;
}

.ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_block_input {
  width: 500px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr ;
      grid-template-columns: 1fr;
  grid-gap: 10px;
  background: rgba(17, 17, 17, 0.02);
  border-radius: 24px;
}

.ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_block_input .ParolUnut_1_div1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 20px;
}

.ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_block_input .ParolUnut_1_div1 select {
  border: none;
  background: none;
  font-size: 18px;
}

.ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_block_input div input {
  font-size: 18px;
  padding: 20px 30px;
  outline: none;
  border: none;
  background: none;
  width: 100%;
  border-radius: 20px;
}

.ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_tasdiq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 18px;
}

.ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_tasdiq p {
  cursor: pointer;
  font-size: 16px;
  padding: 15px 15px;
  background: #FAFAFA;
  border-radius: 10px;
}
.react-tel-input .form-control {
   position: relative !important;
   font-size: 17px !important ;
   letter-spacing: .01rem !important;
   margin-top: 0 !important;
   margin-bottom: 0 !important;
   padding-left: 65px !important;
   margin-left: 0 !important;
   background: rgb(248, 248, 248) !important;
   border: none !important;
   border-radius: 24px !important;
   line-height: 25px !important;
   height: 100% !important;
   width: 100% !important;
   outline: none
 }
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
  background-color: transparent;
}
.react-tel-input .flag-dropdown.open .selected-flag{
  background: transparent;
}
.react-tel-input .flag-dropdown{
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  padding: 0 !important;
  border: none!important;
  background-color: rgba(17, 17, 17, 0) !important;
  border-radius: 3px 0 0 3px !important;
}
.react-tel-input .selected-flag {
  padding-left: 25px !important;
}
.phone-flag {
  margin: 0!important;
  background: transparent !important;
  padding-left: 0!important;
}
.ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_tasdiq p:hover {
  background: #E7F0FF;
  border: none;
  outline: none;
  color: #006aff;
  border-radius: 10px;
  padding: 15px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.6s;
}
.ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_tasdiq button {
  background: #E7F0FF;
  border: none;
  outline: none;
  color: #006aff;
  border-radius: 10px;
  padding: 15px 15px;
  font-size: 16px;
  cursor: pointer;
}
.ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_tasdiq button:hover {
  padding: 15px 15px;
  background: #FAFAFA;
  color: #757575;
  font-size: 16px;
  transition: 0.6s;
}

@media (max-width: 550px) {
  .ParolUnut_1_block .ParolUnut_1 {
    width: 90%;
  }
  .ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_block_input {
    width: 100%;
    -ms-grid-columns: 1fr 2fr;
        grid-template-columns: 1fr 2fr;
    grid-gap: 5px;
  }
  .ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_block_input .ParolUnut_1_div1 {
    padding-left: 10px;
  }
  .ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_block_input .ParolUnut_1_div2 input {
    width: 180px;
  }
}

@media (max-width: 375px) {
  .ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_block_input {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
  .ParolUnut_1_block .ParolUnut_1 .ParolUnut_1_block_input .ParolUnut_1_div2 input {
    width: 120px;
  }
}
