.question {
   width: 100%;
   padding: 0 40px;
}

.wrapper-question {
   max-width: 1251px;
   margin: 0 auto;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.question-btns {
   width: 202px;
   display: flex;
   justify-content: space-between;
}

.question-btns__text {
   width: 81px;
   height: 78px;
   border: 0.5px dashed #7E7E7E;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: 400;
   font-size: 40px;
   color: #7E7E7E;
}

.question-container {
   width: 100%;
   height: 248px;
   margin-top: 20px;
   border: 0.5px dashed #5B5B5B;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
}

.question-add {
   width: 71px;
   height: 71px;
   border-radius: 50%;
   background: #006AFF;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 20px;
}

.question-container__text {
   font-weight: 400;
   font-size: 14px;
   line-height: 17px;
}

@media (max-width:780px) {
   .question {
      margin-top: 100px;
   }

   .question-title {
      font-size: 14px;
      line-height: 17px;
      text-align: center;
   }
}