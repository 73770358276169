.question-check {
   width: 100%;
   padding: 40px;
}

.question-check-container {
   max-width: 1250px;
   margin: 0 auto;
}


.question-check-head {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin: 0 auto;
   flex-wrap: wrap;
}

.question-check-btns {
   width: 200px;
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
}

.question-check-btn {
   width: 60px;
   height: 58px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 0.5px dashed #7E7E7E;
   border-radius: 10px;
   cursor: pointer;
}

.question-check__boold-btn {
   font-size: 25px;
}

.question-check__em-btn {
   font-size: 25px;
   color: #006AFF;
}

.question-check__u-btn {
   font-size: 25px;
   color: #F24E1E;
}

.question-check__warning {
   color: red;
   margin: 24px 0;
}

.question-check-image-box {
   display: flex;
   justify-content: center;
   align-items: center;
}

.question-check-image-box__item {
   width: 100%;
   max-width: 1250px;
   max-height: 420px;
   background-position: center center;
   background-size: 100%;
}

.btn-change {
   background: rgba(217, 217, 217, 0.5);
   border: 1px solid #FFFFFF;
   backdrop-filter: blur(0.5px);
   width: 228px;
   height: 67px;
   border-radius: 50px;
   color: #FFFFFF;
   text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   z-index: 99;
   position: absolute;
   align-self: center;
}

.question-check-text-box textarea {
   margin: 26px 0 36px;
   width: 100%;
   border: none;
   outline: none;
   min-height: 100px;
   background: rgba(217, 217, 217, 0.15);
   border-radius: 10px;
   padding: 15px 15px;
}

.variant-text-container__cell {
   margin-bottom: 31px;
}

.variant-text-container__cell__title {
   margin: 40px 0 20px;
}

.variant-text-container__cell__item {
   background: rgba(217, 217, 217, 0.15);
   border-radius: 10px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 25px 26px;
}

.variant-text-container__cell .question-variant-box {
   width: 100%;
}

.variant-text-container__cell__item input {
   padding: 10px 0;
   border: none;
   background: transparent;
   font-size: 15px;
   outline: none;
}
.desc-text {
   padding: 10px 10px;
   margin-top: 10px;
   border-radius: 5px;
   min-height: 100px;
   border: none;
   background: rgba(217, 217, 217, 0.15);
   font-size: 15px;
   outline: none;
}
.change-type-btn {
   display: flex;
   width: 100%;
   justify-content: end;
}
.change-type-btn button {
   background: #006aff;
   color: white;
   border-radius: 10px;
   border: none;
   margin-bottom: 10px;
   align-items: end;
   text-align: end;
   padding: 20px 10px;
}
.comment-box-title {
   margin: 80px 0 20px;
}

.comment-check-text-box {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.comment-check-text-box p {
   margin: 26px 0 36px;
   background: rgba(217, 217, 217, 0.15);
   border-radius: 10px;
   padding: 49px 35px;
   width: 1070px;
}

.img-btn {
   width: 76px;
   height: 76px;
   background: rgba(17, 17, 17, 0.03);
   border: 1px solid #006AFF;
   border-radius: 18px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.change-save-btn {
   width: 100%;
   padding: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.change-btn {
   width: 182px;
   height: 51px;
   background: #006AFF;
   border-radius: 10px;
   border: none;
   color: #fff;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
}

.modal-container {
   position: fixed;
   top: 0;
   left: 0;
   background: rgba(0, 0, 0, .2);
   width: 100vw;
   height: 100vh;
}
.modal-container .modal-body {
   width: 500px;
   height: 300px;
   background: #FFFFFF;
   box-shadow: 0 0 15px -3px rgba(17, 17, 17, 0.03);
}
@media (max-width:780px) {
   .question-check {
      margin-top: 80px;
   }

   .question-check-text-box p {
      font-size: 3vw;
   }

   .comment-check-text-box p {
      font-size: 3vw;
      padding: 20px;
      max-width: 80%;
   }

   .img-btn {
      width: 50px;
      height: 50px;
   }

   .img-btn img {
      width: 30px;
   }

   .btn-change {
      width: 90px;
      height: 40px;
      font-size: 12px;
   }


   .variant-text-container__cell__item {
      padding: 20px;
   }

   .variant-text-container__cell__item p {
      max-width: 80%;
      font-size: 12px;
   }

   .input-box {
      width: 30px;
      height: 20px;
   }

   .question-check-head {
      margin: 20px 0;
      gap: 20px;
   }

   .question-check-btn {
      width: 45px;
      height: 40px;
   }

   .question-check__warning {
      font-size: 1em;
   }
}