
.main-component {
  max-width: 1380px;
  margin: 0 auto;
}
.navbar-main-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 109px;
  padding: 24px 100px 23px 100px;
  border-bottom: 1px solid rgba(17, 17, 17, 0.06);
}
.middle-main-navbar-menu {
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  width: 30%;
  opacity: 0
}
.ref-menu {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.ref-menu p {
  font-size: 13px;
  color: rgba(17, 17, 17, 0.36);
  margin-left: 10px;
  margin-bottom: 10px;
  width: 80%;
}
.ref-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ref-input input {
  padding: 18px 13px 18px 18px;
  font-size: 16px;
  color: rgba(17, 17, 17, 0.72);
  outline: none;
  border: none;
  background: rgba(17, 17, 17, 0.02);
  border-radius: 12px;
  width: 150px;
}
.ref-input div {
  width: 55px;
  height: 55px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(17, 17, 17, 0.02);
  border-radius: 12px;
  margin-left: 6px;
}
.left-main-navbar-menu img{
  width: 120px;
  /*height: 64px;*/
  aspect-ratio: 4/3 !important;
}
.profil-main-navbar {
  display: flex;
  align-items: center;
  margin-left: 33px;
  cursor: pointer;
}
.right-main-navbar-menu {
  position: relative;
}

.right-main-navbar-menu:hover .login_popup {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
  pointer-events: all;
  z-index: 10;
}

.login_popup {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(20px);
  padding-top: 20px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 10;
  pointer-events: none;
}

.login_popu__body {
  background-color: #E7F0FF;
  border-radius: 8px;
  min-width: 160px;
}
.login_popup__item {
  padding: 10px 20px;
  text-align: center;
  position: relative;
  color: #0178B6;
  cursor: pointer;
}

.login_popup__item:not(:last-child):before {
  content: "";
  width: 70%;
  height: 1px;
  background-color: #bdc4d0;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  position: absolute;
}

.middle-main-navbar-menu a {
  text-decoration: none;
  color: rgba(17, 17, 17, 0.54);
  font-size: 14px;
}
.right-main-navbar-menu {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.language-navbar-main {
  display: flex;
  align-items: center;
}
.language-navbar-main {
  color: #7e7e7e;
}
.language-navbar-main {
  margin-left: 32px;
}
.profil-main-navbar {
  display: flex;
  align-items: center;
  margin-left: 33px;
}
.profil-main-navbar .image-navbar{
  width: 55px;
  height: 55px;
}
.profil-main-navbar .image-navbar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-repeat: no-repeat;
  box-shadow: 0 2px 5px 0 rgba(50, 50, 105, 0.15), 0 1px 1px 0 rgba(0, 0 ,0,  0.05);
  object-fit: cover;
}
#profile-title-main-navbar {
  color: #0178B6;
  font-size: 18px;
  margin-left: 10px;
}
.main-menu-to-sections {
  width: 250px;
padding: 15px;
}
.top-main-menu-to-sections {
  height: 250px;
  width: 100%;
}
.bottom-main-menu-to-sections {
  background-color: rgb(88, 92, 97);
  height: 56px;
  width: 100%;
  margin-top: 128px;
}
.main-menu-section-links a {
  width: 210px;
  height: 52px;
  padding: 8px 40px 8px 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}
.main-menu-section-links .selected {
  background-color: #e7f0ff;
  border-radius: 12px;
}
.main-menu-section-links .selected .material-ui-icon {
  background-color: #fdfdfe;
  padding: 5px;
  border-radius: 8px;
}
.main-menu-section-links .selected .material-ui-icon .MuiSvgIcon-root {
  color: #0178B6;
}
.selected .section-links-title {
  color: #0178B6;
  font-family: Gilroy-Bold;
}
.main-menu-to-sections {
  border-right: 1px solid rgba(17, 17, 17, 0.06);
}
.section-links-title {
  margin-left: 16px;
  color: rgba(17, 17, 17, 0.36);
  font-size: 16px;
}
.bottom-main-menu-to-sections {
  background: #f1f1f1;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 19.67px 31.67px 19.67px 31.67px;
  outline: none;
  border: none;
}
#material-ui-icon-title {
  color: #7e7e7e;
  margin-left: 7.67px;
  font-family: Gilroy-Medium;
}
.section-content-for-teachers {
  width: 100%;
  overflow-y: scroll;
}
.section-content-for-teachers::-webkit-scrollbar {
  display: none !important;
  scrollbar-color: #000;
}
.main-content-for-teachers {
  display: flex;
  width: 100%;
  height: 88.2vh;
}
.bottom-main-menu-to-sections {
  cursor: pointer;
}
.courses-open-modal-title {
  display: flex;
  align-items: center;
}
.courses-open-modal-title span {
  margin-left: 10px;
  color: #111111;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-family: Gilroy-Medium;
}
.course-name-title {
  margin-top: 24px;
}
.validation-course-input {
  margin-top: 10px;
  color: red !important;
  font-size: 20px;
}
.error-input {
  border:  1px solid red !important;
}
.course-name-title-link{
  margin-top: 12px
}
.course-name-title p {
  margin-left: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(17, 17, 17, 0.3);
  font-family: Gilroy-Medium;
}
.course-name-title input {
  margin-top: 12px;
  width: 482px;
  height: 76px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #111111;
  padding: 24px 24px;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
  border: none;
  outline: none;
  font-family: Gilroy-Medium;
}
.course-name-title-link input {
  margin-top: 6px;
  width: 482px;
  height: 76px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #111111;
  padding: 24px 24px;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
  border: none;
  outline: none;
  font-family: Gilroy-Medium;
}
.category-select-variant {
  width: 482px;
  height: 76px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-top: 12px;
  color: #111111;
  padding: 24px 24px;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
  border: none;
  outline: none;
}
.modal-alert {
  width: 200px;
  height: 100px;
  position: fixed;
  top: 25px;
  right: 15px;
  transition: 0.3s;
  animation: modalAlert 0.6s linear forwards;
  transform: translateX(150%);
  opacity: 0;
  z-index: 10000000000000000;
}

@keyframes modalAlert {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.modal-alert .modal-alert-title{
  border: 1px solid transparent;
  background-color: #e7f0ff;
  border-radius: 10px;
}
.modal-alert .modal-alert-title h1 {
  font-size: 13px;
  font-family: Gilroy-Medium;
  color: #0178B6;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.modal-alert .modal-alert-title img {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 210px;
  top: 2px;
  border-radius: 10px;
  background: #e7f0ff;
  padding: 7px;
}

/*.category-select-variant{*/
/*  width: 360px;*/
/*  height: 76px;*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*  font-size: 24px;*/
/*  color: #111111;*/
/*  background: rgba(17, 17, 17, 0.03);*/
/*  border-radius: 18px;*/
/*  border: none;*/
/*  outline: none;*/
/*}*/
.course-image-file {
  margin-top: 18px;
}
.course-image-file p {
  margin-left: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(17, 17, 17, 0.3);
  font-family: Gilroy-Medium;
}
.course-type {
  margin-top: 18px;
  display: flex;
  align-items: center;
}
.left-course-type p {
  margin-left: 22px;
  margin-top: -17px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(17, 17, 17, 0.3);
  font-family: Gilroy-Medium;
}
.category-select-variant2 {
  width: 235px;
  margin-top: 12px;
  height: 76px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #111111;
  padding: 24px 24px;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
  border: none;
  outline: none;
  font-family: Gilroy-Medium;
}
.right-course-type {
  margin-left: 12px;
}
.right-course-type p {
  margin-left: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(17, 17, 17, 0.3);
  font-family: Gilroy-Medium;
}
.about-course-write-content {
  margin-top: 18px;
}
.about-course-write-content p {
  margin-left: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(17, 17, 17, 0.3);
  font-family: Gilroy-Medium;
}
.about-course-write-content textarea {
  width: 482px;
  font-family: Gilroy-Medium;
  margin-top: 12px;
  height: 160px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #111111;
  padding: 24px 24px;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
  border: none;
  outline: none;
}
.about-course-write-content textarea::-webkit-scrollbar {
  display: none;
}
.modal-close-and-upload-buttons {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
#close-btn {
  border: 3px solid rgba(17, 17, 17, 0.04);
  box-sizing: border-box;
  border-radius: 18px;
  width: 225px;
  height: 76px;
  font-weight: 500;
  font-size: 18px;
  background-color: #fff;
  color: #0178B6;
  cursor: pointer;
}
#upload-course-btn {
  border: 3px solid rgba(17, 17, 17, 0.04);
  margin-left: 14px;
  box-sizing: border-box;
  border-radius: 18px;
  width: 225px;
  height: 76px;
  font-weight: 500;
  font-size: 18px;
  background-color: #e7f0ff;
  color: #0178B6;
  cursor: pointer;
}
.ReactModal__Content::-webkit-scrollbar {
  display: none;
}
.d-flex-logout {
  display: flex;
  margin-top: 10px;
  cursor: pointer;
}
.right-course-type div {
  display: block;
}
.d-flex-logout .material-ui-icon {
  margin-left: 10px;
}

.logout-text {
  color: rgba(17, 17, 17, 0.36);
  margin-left: 16px;
  font-size: 16px;
  margin-top: 5px;
}
#video-cover, #video {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.course-image-file {
  display: flex;
  flex-direction: column;
}
.course-file-name-label {
  display: flex;
  align-items: center;
  margin-top: 18px;
}

.course-file-name-div {
  padding: 24px 0px 10px 24px;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
  width: 390px;
  height: 76px;
  margin-right: 12px;
}
.course-file-name {
  font-family: Gilroy-Medium;
  font-size: 24px;
  color: #111111;
}
.video-cover-div {
  padding: 22px 22px;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
}
.navbar-main-menu div {
  /*display: none;*/
}
/* .navbar-main-menu div {
  display: none;
} */

@media (max-width: 1024px) {
  /*.middle-main-navbar-menu {*/
  /*  display: none;*/
  /*}*/
  .ReactModal__Overlay .ReactModal__Content {
    height: 500px !important;
    margin-top: 100px;
  }
  .section-links-title {
    margin-left: 11px;
  }
  .section-content-for-teachers {
    width: 828px;
  }
  .navbar-main-menu {
    justify-content: space-between;
  }
  
  .main-menu-section-links a {
    width: 142px;
  }
  .bottom-main-menu-to-sections {
    padding: 19.67px 14.67px 19.67px 12.67px;
    margin-top: 135px;
  }
  .bottom-main-menu-to-sections span {
    font-size: 11px;
  }
  .main-content-for-teachers {
    /* height: 81vh; */
  }
  .react-modal-binnasa-div-outside
    .ReactModal__Content
    .ReactModal__Content--after-open {
    height: 490px;
  }
}
@media (max-width: 768px) {

  .react-modal-binnasa-div-outside {
    display: none;
  }

  .course-stats-in-numbers {
    padding: 0;
  }

  .d-flex-logout {
    display: none;
  }

  .navbar-main-menu {
    padding: 0 20px !important;
    z-index: 1000;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    background: white;
    margin: 0 auto;
    padding: 0;
    height: 90px;
  }
  .middle-main-navbar-menu {
    display: none;
  }
  .main-menu-to-sections {
    display: flex;
    padding: 0;
    padding-top: 25px;
    border-right: none;
    background-color: #fff;

    border-top: 2px solid #1111110f;
  }
  .main-content-for-teachers {
    flex-direction: column-reverse;
  }

  .main-menu-section-links {
    padding: 0 10px;

  }
  #jvlabelWrap{
    bottom: 140px !important;
  }
  .wrap_e962._orientationRight_be35.__jivoMobileButton{
    bottom: 90px;
  
  }
  ._orientationRight_be35 .button_ba21{
    margin-right: 12px !important;
  }
  .button_ba21{
    width: 50px !important;
    height: 50px !important;
  }
  .logoIconCloud_afa8.icons_dbd4{
   background-size: 65% !important;
background-position: center;
  }
  .main-menu-section-links a {
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 50px;
    /* width: 125px;
    height: 72px; */
    padding: 0;
    box-sizing: border-box;
  }
  .top-main-menu-to-sections {
    justify-content: space-between;
    height: 80px;
    background: white;
    display: flex;
    flex-direction: row;
  }
  .bottom-main-menu-to-sections {
    display: none;
  }
  .bottom-main-menu-to-sections {
    margin-top: 0;
    width: 85px !important;
  }
  .add-course-btn {
    font-size: 13px;
  }
  .main-menu-section-links .selected .material-ui-icon {
    padding: 0 !important;
  }
  .main-menu-section-links .selected {
    background: transparent;
  }
  .main-menu-section-links {
    height: auto;
  }
  .main-content-for-teachers {
    /* height: 82.2vh; */
  }
  .section-content-for-teachers {
    width: 100%;
    padding: 0 20px;
  }
}
@media (max-width: 540px) {
  .right-course-type {
    margin-left: 0;
  }

  .modal-close-and-upload-buttons #upload-course-btn {
    margin-left: 0px !important;
    margin-top: 10px;
  }
  .modal-close-and-upload-buttons {
    flex-direction: column;
  } 
   .course-type {
     flex-direction: column;
   }
  .main-menu-section-links6 {
    display: none;
  }
  .main-menu-section-links a {
    /* width: 105px;
    height: 66px; */
    /* padding: 4px 4px 4px 5px; */
  }
  .main-content-for-teachers {
    /* height: 545px; */
  }
  .navbar-main-menu .middle-main-navbar-menu {
    opacity: 0;
    display: none;
  }
  .navbar-main-menu .right-main-navbar-menu {
    margin-left: 20px;
  }
  .section-content-for-teachers {
    /* width: 540px; */
  }
  .section-links-title {
    margin-left: 0px;
    margin-top: 3px;
  }

  .main-menu-section-links .selected {
    background-color: #fff;
    border-radius: 0px;
  }
  .ReactModal__Overlay .ReactModal__Content {
    width: 495px !important;
    padding: 28px 12px !important;
  }
  .course-name-title input,
  .course-name-title select {
    width: 455px;
  }
  .course-file-name-div {
    width: 100% !important;
  }
  .category-select-variant2 {
    width: 100%;
  }
  .about-course-write-content textarea {
    width: 456px;
  }
  .profil-main-navbar .image-navbar{
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 480px) {
  .main-content-for-teachers {
    /* height: 660px; */
  }
  .section-content-for-teachers {
    /* width: 480px; */
  }
  .main-menu-section-links5 {
    display: none;
  }
  .main-menu-section-links {
    /* margin-left: 15px; */
  }
  .ReactModal__Overlay .ReactModal__Content {
    width: 440px !important;
  }
  .course-name-title input,
  .course-name-title select {
    width: 404px;
  }
  .category-select-variant2 {
    /* width: 200px; */
    
  }
  .about-course-write-content textarea {
    width: 411px;
  }
}
@media (max-width: 425px) {
  .main-content-for-teachers {
    /* height: 824px; */
  }
}
@media (max-width: 411px) {
  .main-content-for-teachers {
    /* height: 560px; */
  }
  .main-menu-section-links .selected {
    /* width: 70px; */
  }
  .main-menu-section-links {
    /* margin-left: 7px; */
  }
  .section-content-for-teachers {
    /* width: 411px; */
  }
  .ReactModal__Overlay .ReactModal__Content {
    width: 315px !important;
  }
  .courses-open-modal-title {
    width: 260px;
  }
  .course-name-title input,
  .course-name-title select {
    font-size: 18px;
    width: 280px;
  }
 
  .category-select-variant2 {
    width: 282px;
  }
  .right-course-type {
    margin-left: 0px;
    margin-top: 10px;
  }
  .about-course-write-content textarea {
    width: 286px;
  }
  
  .modal-close-and-upload-buttons #upload-course-btn,
  .modal-close-and-upload-buttons #close-btn {
    width: 280px;
  }
  
}
@media (max-width: 411px) {
  .section-links-title {
    font-size: 13px;
    font-family: Gilroy-Bold;
  }
}
@media (max-width: 375px) {
  .main-content-for-teachers {
    /* height: 635px; */
  }
  .main-menu-section-links .selected {
    /* width: 50px; */
  }
  .main-menu-section-links a {
    /* padding: 0px;
    width: 100px; */
  }
  .section-content-for-teachers {
    /* width: 370px; */
  }
  .main-menu-section-links {
    /* margin-left: 3px; */
  }
  .main-menu-section-links {
    padding: 0 5px;
  }
  .add-course-btn {
    font-size: 12px;
  }
  .bottom-main-menu-to-sections {
    width: 82px;
  }
}

.modalBox {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 100000000000000000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transform: translateX(-150%);
  transition: all ease 0.5s;
}

.modalBox.active {
  transition: all ease 0.5s;
  transform: translateX(0);
}

.courses-open-modal-title {
  position: absolute;
  top: 20px;
  left: 20px;
}

@media (max-width: 576px) {
  .modalBox {
    padding-top: 80px;
    padding-bottom: 20px;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: scroll;
  }

}

