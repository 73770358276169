.question-position {
   width: 100%;
   /*min-height: 80vh;*/
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 20px;
   overflow-y: hidden;
   padding: 0 20px;
}

.question-position-container {
   display: flex;
   width: 100%;
   height: 100%;
   min-height: 248px;
   max-width: 1142px;
   justify-content: space-evenly;
   flex-wrap: wrap;
   align-items: center;
   border: 0.5px dashed #5B5B5B;
}

.column {
   width: 230px;
   height: 105px;
}

.column-photo {
   width: 230px;
   height: 84px;
   display: flex;
   background: rgba(217, 217, 217, 0.7);
   display: flex;
   justify-content: center;
   align-items: center;
}

.column-text {
   margin-top: 4px;
   width: 230px;
   height: 24px;
   display: flex;
   background: rgba(217, 217, 217, 0.7);
   display: flex;
   justify-content: center;
   align-items: center;
}

.row {
   width: 226px;
   height: 114px;
   display: flex;
   column-gap: 4px;
}

.row-photo {
   width: 113px;
   height: 114px;
   display: flex;
   background: rgba(217, 217, 217, 0.7);
   display: flex;
   justify-content: center;
   align-items: center;
}

.row-text {
   width: 113px;
   height: 114px;
   display: flex;
   background: rgba(217, 217, 217, 0.7);
   display: flex;
   justify-content: center;
   align-items: center;
}

.together {
   width: 235px;
   height: 114px;
   display: flex;
   background: rgba(217, 217, 217, 0.7);
   display: flex;
   justify-content: center;
   align-items: center;
}

.superior {
   width: 224px;
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.superior-photos {
   width: 244px;
   display: flex;
   align-items: center;
   gap: 4px;
}

.superior-photos__item {
   width: 57px;
   height: 84px;
   background: rgba(217, 217, 217, 0.7);
   display: flex;
   align-items: center;
   justify-content: center;
}

.superior-text {
   width: 242px;
   display: flex;
   align-items: center;
   justify-content: center;
   background: rgba(217, 217, 217, 0.7);
}

@media (max-width:770px) {
   .question-position {
      margin-top: 200px;
   }

   .question-position-container {
      padding: 20px;
      column-gap: 10px;
      row-gap: 10px;
   }

}

@media (max-width:654px) {
   .question-positio {
      margin-top: 200px;
   }


   .question-position-container {
      column-gap: 10px;
      row-gap: 10px;
      padding: 20px;
   }

   .question-position-title {
      font-weight: 400;
      font-size: 19px;
      line-height: 27px;
   }
}