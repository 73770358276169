.groups-page {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 20px;
	gap: 100px;
}

.groups-page-filters {
	max-width: 1000px;
	width: 100%;
	display: flex;
	gap: 20px;
}
