.add-create-question {
   width: 100%;
   min-height: 100vh;
   padding: 40px;
}

.add-create-question {
   max-width: 1251px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   row-gap: 94px;
}
.btns-footer {
   display: flex;
   width: 100%;
   justify-content: end;
}
.btn-body {
   width: 50%;
   display: flex;
   justify-content: end;
}
.add-create-question-variants {
   max-width: 1250px;
   margin: 0 auto;
   padding: 40px;
}
.add-create-question-variants .textarea-answer-2 textarea {
   padding: 20px;
}
.add-create-question-variants__title-1 {
   font-weight: 400;
   font-size: 20px;
   line-height: 27px;
   text-align: center;
   align-items: center;
}

.add-create-question-variants__title {
   font-weight: 400;
   font-size: 20px;
   line-height: 27px;
}

.question-variant-chooes-container {
   display: flex;
   margin: 20px 0;

}

.question-variant-chooes {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: 0.5px dashed #5B5B5B;
   border-radius: 10px;
   padding: 10px 20px;
}

.question-variant-box {
   font-weight: 700;
   width: 90%;
}
.question-variant-box-new {
   display: flex;
   justify-content: space-between;
   width: 100%;
}
.question-variant-box-new input {
   width: 100%;
}
.question-textarea {
   background: transparent !important;
   min-height: 100px !important;
}
.question-input {
   padding: 10px 0;
   border: none;
   width: 100%;
   font-size: 15px;
   outline: none;
}
.question-variant-box span {
   font-weight: 500;
}

.question-variant-box__input {
   width: 30px;
   height: 30px;
   cursor: pointer;
}

.question-variant-add-box {
   width: 76px;
   height: 76px;
   margin: 0 8px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: rgba(17, 17, 17, 0.03);
   border-radius: 18px;
   cursor: pointer;
}



.btn-container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 40px 20px;
}

.add-comment-btn {
   padding: 15px 20px;
   border: none;
   background: #006AFF;
   border-radius: 10px;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
   color: #FFFFFF;
}

.add-create-question-btns {
   width: 50%;
   display: flex;
   justify-content: space-between;
}


.btn {
   width: 81px;
   height: 78px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 0.5px dashed #7E7E7E;
   border-radius: 10px;
}

.add-create-text {
   width: 100%;
   display: flex;
   align-items: center;
   flex-direction: column;
   gap: 34px;
}

.add-create-text__title {
   font-weight: 400;
   font-size: 20px;
   line-height: 27px;
}

.add-create-text__input {
   padding: 20px;
   min-height: 250px;
   cursor: pointer;
}

.boold-btn {
   font-size: 40px;
   color: #555555;
}

.em-btn {
   font-size: 40px;
   color: #006AFF;
}

.u-btn {
   font-size: 40px;
   color: #F24E1E;
}

@media (max-width:780px) {
   .add-create-question {
      margin-top: 100px;
   }

   .add-create-question-btns {
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      row-gap: 10px;
      flex-wrap: wrap;
   }

   .question-variant-chooes-container {
      flex-wrap: wrap;

      gap: 10px;
   }

   .question-variant-box {
      font-size: 1.9vw;
   }

   .question-variant-box__input {
      width: 20px;
   }

   .question-variant-add-box {
      width: 50px;
      height: 50px;
   }

   .question-variant-add-box img {
      width: 20px;
   }

   .btn {
      width: 41px;
      height: 35.5px;
   }

   .boold-btn {
      font-size: 20px;
      color: #555555;
   }

   .em-btn {
      font-size: 20px;
      color: #006AFF;
   }

   .u-btn {
      font-size: 20px;
      color: #F24E1E;
   }

   .add-text__btn {
      width: 140px;
   }
}