.add-question {
   width: 100%;
   height: 100vh;
}

.add-question {
   max-width: 1251px;
   padding: 40px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   row-gap: 94px;
}

.add-question-btns {
   width: 50%;
   display: flex;
   justify-content: space-between;
}


.btn {
   width: 81px;
   height: 78px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 0.5px dashed #7E7E7E;
   border-radius: 10px;
   cursor: pointer;
}

.add-text {
   width: 100%;
   display: flex;
   align-items: center;
   flex-direction: column;
   gap: 34px;
}

.add-text__input {
   padding: 20px;
   min-height: 250px;
}

.add-text__title {
   font-weight: 400;
   font-size: 20px;
   line-height: 27px;
}

.add-text__btn {
   width: 182px;
   height: 51px;
   background: #006AFF;
   border-radius: 10px;
   color: #fff;
   border: none;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
}

.boold-btn {
   font-size: 40px;
   color: #555555;
}

.em-btn {
   font-size: 40px;
   color: #006AFF;
}

.u-btn {
   font-size: 40px;
   color: #F24E1E;
}

@media (max-width:780px) {
   .add-question {
      margin-top: 100px;
   }

   .add-question-btns {
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      row-gap: 10px;
      flex-wrap: wrap;
   }

   .btn {
      width: 41px;
      height: 35.5px;
   }

   .boold-btn {
      font-size: 20px;
      color: #555555;
   }

   .em-btn {
      font-size: 20px;
      color: #006AFF;
   }

   .u-btn {
      font-size: 20px;
      color: #F24E1E;
   }

   .add-text__btn {
      width: 140px;
   }
}