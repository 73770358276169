.add-variants {
   width: 100%;

   height: 100vh;
}

.add-variants-wrapper {
   max-width: 1251px;
   margin: 0 auto;
   padding: 40px;
}

.add-variants-img {
   width: 100%;
   background-position: center;
   background-size: cover;
   margin: 23px 0;
}

.add-variants-text {
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 20px;
}

.add-variants-text__input {
   padding: 20px;
   min-height: 200px;
}

.add-variants-btn {
   width: 71px;
   height: 71px;
   background: #006AFF;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
}

@media (max-width:780px) {
   .add-variants {
      margin-top: 80px;
   }

   .add-variants-btn {
      width: 51px;
      height: 51px;
   }
}