table {
   border: 1px solid #fff;
   border-collapse: collapse;
   margin: 0;
   padding: 0;
   width: 100%;
   table-layout: fixed;
   overflow: scroll;
}

ul,
ol,
li {
   list-style: none;
}

table caption {
   text-align: start;

}

.modale-window {
   width: 190px;
   height: 111px;
   background: #FFFFFF;
   box-shadow: 0px 4px 4px 2px rgba(106, 100, 100, 0.432);
   border-radius: 10px;
   padding: 30px 0 0 15px;
}

.show {
   display: none;
}

.modale-window__item {
   font-style: italic;
   font-weight: 400;
   font-size: 16px;
   line-height: 20px;
   color: #333333;
   margin: 0 0 10px;
   cursor: pointer;
   border: none;
   background: none;
}

.export {
   display: flex;
   align-items: center;
   gap: 10px;
   font-weight: 500;
   font-size: 14px;
   line-height: 16px;
   margin: 20px 0 15px 5px;
   cursor: pointer;
}

.export span {
   width: 10px;
   height: 5px;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-top: 5px solid #0195C6;

}

.houre {
   text-decoration: underline;
   color: #0195C6;
}

table tr,
td {
   border: 1px solid #ddd;
   padding: .35em;
}

table th,
table td {
   padding: 1rem;
}


.col {
   color: #FFFFFF;
   border: 1px solid #ddd;
   background: #0195C6;
   font-weight: 800;
   font-size: 12px;
   line-height: 130%;
   text-transform: uppercase;
}

@media screen and (max-width: 600px) {
   table {
      border: 0;
   }

   table caption {
      font-size: 1.3em;
   }



   table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
   }

   .col {
      color: #FFFFFF;
      border: 1px solid #ddd;
      background: #0195C6;
      font-weight: 800;
      font-size: 12px;
      line-height: 130%;
      text-transform: uppercase;
   }

   table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
   }

   table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
   }

   table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      overflow: scroll;
      color: #0195C6;
   }

   table td:last-child {
      border-bottom: 0;
   }
}

/* general styling */
body {
   font-family: "Open Sans", sans-serif;
   line-height: 1.25;
}