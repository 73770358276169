.filter-page {
   width: 100%;
}

.container-filter {
   max-width: 1257px;
   margin: 0 auto;
}

.filter-page-selects {
   width: 100%;
   display: flex;
   justify-content: space-around;
   align-items: center;
   flex-wrap: wrap;
   column-gap: 20px;
   row-gap: 20px;
   margin-top: 40px;
}

@media (max-width:654px) {
   .filter-page-selects {
      margin: 0 20px;
      margin-top: 200px;
      column-gap: 10px;
      row-gap: 10px;
   }
}

@media (max-width:770px) {
   .filter-page-selects {
      margin: 0 20px;
      margin-top: 200px;
      column-gap: 10px;
      row-gap: 10px;
   }
}