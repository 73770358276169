.main-content-div-for-profile-wrapper {
  width: 100%;
}
.navbar-of-main-content-for-profile {
  height: 62px;
  padding: 30px 0px 0px 49px;
  background: #fff;
  border-bottom: 1px solid rgba(17, 17, 17, 0.06);
}
.navbar-of-main-content-for-profile a {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin-left: 36px;
  color: rgba(17, 17, 17, 0.54);
  padding-bottom: 12px;
  font-family: Gilroy-Medium;
}
.navbar-of-main-content-for-profile .selected-for-prof-info {
  color: #111;
  border-bottom: 2px solid #111111;
}
.main-content-for-prof-info-wrapper-div {
  padding: 36px 112px 163px 30px;
}
.profile-picture-for-profinfo {
  width: 172px;
  height: 172px;
  border-radius: 18px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid #006affab;
}

.profile-picture-for-profinfo label {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 15px 15px;
  background: linear-gradient(transparent , #fff);
  cursor: pointer;
}

.profile-picture-for-profinfo label img {
  object-fit: none;
}

/* .profile-picture-for-profinfo:before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 64px;
  box-shadow: 10.5px 8.5px 0 0 white;
  border-radius: 19pt;
  transform: rotateZ(5deg);
}

.profile-picture-for-profinfo:after {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  position: absolute;
  z-index: 1;
  bottom: 64px;
  right: 0;
  box-shadow: 6.5px 11.5px 0 0 white;
  border-radius: 19pt;
  transform: rotateZ(-16deg);
} */

.profile-picture-for-profinfo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-info-for-profile-div {
  display: flex;
}
.right-top-info-for-profile-div {
  display: flex;
  flex-direction: column;
  padding-left: 36px;
}
.right-top-info-top-part-of-profinfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 820px;
}
.right-top-profile-div-inputs {
  background: rgba(17, 17, 17, 0.02);
  border-radius: 18px;
  padding: 24px 0px 24px 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: rgba(17, 17, 17, 0.72);
  outline: none;
  border: none;
  font-family: Gilroy-Medium;
}
.middle-main-profinfo-for-profinfo-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px 20px;
  /* padding-top: 36px; */
  width: 820px;
  /* border-bottom: 2px solid rgba(17, 17, 17, 0.06); */
  padding-bottom: 30px;
}
.info-divs-inside-inputs-and-titles input {
  background: rgba(17, 17, 17, 0.02);
  border-radius: 18px;
  padding: 24px 0px 24px 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: rgba(17, 17, 17, 0.72);
  outline: none;
  border: none;
  margin-top: 5px;
  width: 400px;
  font-family: Gilroy-Medium;
}
.info-divs-inside-inputs-and-titles p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  color: rgba(17, 17, 17, 0.36);
  font-family: Gilroy-Medium;
}
.info-divs-inside-inputs-and-titles .alone-input-of-profinfo-div {
  width: 312px;
}
.alone-input-of-profinfo-div-outside-div-block{
  display: flex;
  align-items: center;
}
.object-fit-none{
  object-fit: none !important;
}
.info-divs-inside-inputs-and-titles button {
  background: rgba(17, 17, 17, 0.02);
  border-radius: 10px;
  outline: none;
  border: 3px solid #006aff;
  width: 65px;
  height: 65px;
  margin-left: 12px;
  object-fit: cover;
  vertical-align: middle;

}
.info-divs-inside-inputs-and-titles button img {
  justify-content: center;
  align-items: center;
  object-fit: none;
  background-clip: revert;
  width: 100%;
  height: 100%;
  text-align: center;
}
.second-middle-main-profinfo-for-profinfo-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 40px;
  grid-gap: 20px 24px;
  padding-bottom: 54px;
  border-bottom: 2px solid rgba(17, 17, 17, 0.06);
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
.third-middle-main-profinfo-for-profinfo-div {
  display: flex;
  flex-direction: column;
}
.thirds-middle-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 40px;
  grid-gap: 20px 24px;
}
.btn-thirds-middle-main {
  display: flex;
  justify-content: center;
}
.third-middle-main-profinfo-for-profinfo-div .btn-thirds-middle-main button {
  border: none;
  box-sizing: border-box;
  border-radius: 18px;
  width: 225px;
  height: 76px;
  font-weight: 500;
  font-size: 24px;
  background-color: #e7f0ff;
  color: #006aff;
  cursor: pointer;
  margin-top: 30px;
  font-family: Gilroy-Medium;
}
.edit-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 50px;

}
.edit-btn button {
  width: 150px;
  height: 50px;
  background: #e7f0ff;
  font-size: 17px;
  border-radius: 18px;
  color: #006aff;
  outline: none;
  border: none;
}
.main-content-for-profcard-wrapper {
  padding: 40px 49px 0px 49px;
}
.error-input {
  border-color: red !important;
}
.top-div-of-profcard-for-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 24px;
}
.btn-profcard-middle-main button {
  border: none;
  box-sizing: border-box;
  border-radius: 18px;
  width: 225px;
  height: 76px;
  font-weight: 500;
  font-size: 24px;
  background-color: #e7f0ff;
  color: #006aff;
  cursor: pointer;
  margin-top: 30px;
  font-family: Gilroy-Medium;
}
.profcard-wrapper-div-for-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 24px;
}
.profpassword-wrapper-div-for-content {
  padding: 40px 49px 0px 49px;
}
.top-div-for-profpassword-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 24px;
}
.top-div-for-profpassword-input input {
  background: rgba(17, 17, 17, 0.02);
  border-radius: 18px;
  padding: 24px 0px 24px 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: rgba(17, 17, 17, 0.72);
  outline: none;
  border: none;
  margin-top: 18px;
  width: 400px;
}
.top-div-for-profpassword-input p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: rgba(17, 17, 17, 0.36);
}
.bottom-div-for-profpassword-button {
  margin-top: 30px;
}
.bottom-div-for-profpassword-button button {
  border: none;
  box-sizing: border-box;
  border-radius: 18px;
  width: 225px;
  height: 76px;
  font-weight: 500;
  font-size: 24px;
  background-color: #e7f0ff;
  color: #006aff;
  cursor: pointer;
  margin-top: 30px;
  font-family: Gilroy-Medium;
}
.laleb-img {
  padding: 25px 27px 25px 27px;
  margin-left: 12px;
}

.required-input{
  color: red!important;
  padding-left: 5px;
  /*font-size: 20px;*/
}

.SignUpPage3_label{
  color: rgba(17, 17, 17, 0.72);
  font-size: 18px;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 24px 30px;
  background: rgba(17, 17, 17, 0.02);
  border-radius: 18px;
  cursor: pointer;
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  color: rgba(117, 117, 117, 1);
}
.SignUpPage3_label input {
  margin: 0;
  width: 30px !important;
  font-weight: 500;
  font-size: 24px;
  color: rgba(17, 17, 17, 0.54);
  position: relative;
}
.input-wer {
  width: 310px!important;
}
.SignUpPage3_label input[type="radio"] {
  height: 30px;
  width: 30px !important;
}
.category-select-variant-profile {
  width: 100%;
  height: 76px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  padding: 24px 24px;
  margin-top: 12px;
  color: #111111;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
  border: none;
  outline: none;
}

.SignUpPage3_label_p{
  font-size: 18px;
  display: block;
  color: rgba(17, 17, 17, 0.36);
  padding-bottom: 18px;
  padding-left: 6px;
  font-family: Gilroy-Medium;
}
.display-hidden {
  display: none;
  opacity: 0;
}
.mt-2 {
  margin-top: 5px;
}
.right-top-info-top-part-of-profinfo-mobile-responsive {
  display: none;
}
.divs-for-prof-passwaord-criteria p,
.divs-for-prof-passwaord-criteria input {
  font-family: Gilroy-Medium;
}
@media (max-width: 1280px) {
  .right-top-info-top-part-of-profinfo input {
    width: 360px;
    margin-top: 20px;
    margin-left: -10px;
  }
  .right-top-info-top-part-of-profinfo {
    grid-gap: 0;
  }
  .middle-main-profinfo-for-profinfo-div input {
    width: 360px;
    margin-top: 20px;
    margin-left: -10px;
  }
  .middle-main-profinfo-for-profinfo-div {
    grid-gap: 0;
  }
  .info-divs-inside-inputs-and-titles {
    margin-top: 20px;
  }
  .second-middle-main-profinfo-for-profinfo-div {
    grid-gap: 0;
  }

  .second-middle-main-profinfo-for-profinfo-div input {
    width: 360px;

    margin-top: 20px;
    margin-left: -10px;
  }
  .thirds-middle-main {
    grid-gap: 0px;
    width: 800px;
  }
  .thirds-middle-main input {
    width: 340px;
  }
}
@media (max-width: 1024px) {
  .profile-picture-for-profinfo img,
  .profile-picture-for-profinfo {
    width: 150px;
    height: 150px;
  }
  .right-top-info-top-part-of-profinfo input {
    width: 298px;
    font-size: 20px;
  }
  .right-top-info-top-part-of-profinfo {
    width: 626px;
  }
  .info-divs-inside-inputs-and-titles input {
    width: 300px;
  }
  .middle-main-profinfo-for-profinfo-div,
  .second-middle-main-profinfo-for-profinfo-div,
  .thirds-middle-main {
    width: 626px;
  }
  .alone-input-of-profinfo-div-outside-div-block button {
    margin-top: 15px;
    margin-left: -12px;
  }
  .main-content-for-profcard-wrapper {
    padding: 40px 49px 33px 49px;
  }
  .profpassword-wrapper-div-for-content {
    padding-bottom: 30px;
    padding-left: 24px;
  }
  .divs-for-prof-passwaord-criteria input {
    width: 385px;
  }
  .top-div-for-profpassword-input {
    grid-gap: 14px 14px;
  }
}
@media (max-width: 768px) {

  .right-top-info-for-profile-div {
    padding: 20px 0;
  }

  .profpassword-wrapper-div-for-content {
    padding: 20px 0;
  }

  
  
  .second-middle-main-profinfo-for-profinfo-div,
  .third-middle-main-profinfo-for-profinfo-div {
   display: flex;
  }
  .btn-thirds-middle-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar-of-main-content-for-profile {
    padding: 20px 0 0 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: auto;
  }
  .navbar-of-main-content-for-profile a{
    margin: 0;
  }

  .main-content-for-profile {
    padding: 80px 0;

  }
  .main-content-for-profile {
    height: 100vh;
  }
  .right-top-info-top-part-of-profinfo {
    grid-template-columns: 1fr;
  }
  .right-top-profile-div-inputs {
    width: 100%;
  }
  .middle-main-profinfo-for-profinfo-div {
    grid-template-columns: 1fr;
  }
  .second-middle-main-profinfo-for-profinfo-div {
    grid-template-columns: 1fr;
  }
  .thirds-middle-main {
    grid-template-columns: 1fr;
  }
  .btn-thirds-middle-main {
    text-align: left;
  }
  .top-div-of-profcard-for-inputs {
    grid-template-columns: 1fr;
  }
  .top-div-for-profpassword-input {
    grid-template-columns: 1fr;
  }
  .alone-input-of-profinfo-div-outside-div-block button {
    margin-left: 10px;
  }
  .main-content-for-prof-info-wrapper-div {
    padding: 0;
  }
}
@media (max-width: 540px) {
  .main-content-for-profile {
    /* height: 78.5vh; */
  }
  .left-top-info-for-profile-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    text-align: center;
  }
  .top-info-for-profile-div {
    flex-direction: column;
  }
  .right-top-info-for-profile-div {
    padding: 5px 0;
  }
  .third-middle-main-profinfo-for-profinfo-div {
    margin-bottom: 80px;
  }
  .right-top-info-top-part-of-profinfo {
    display: none;
  }
  .bottom-right-top-info-top-part-of-profinfo-mobile-responsive {
    margin-top: 22px;
  }
  .bottom-right-top-info-top-part-of-profinfo-mobile-responsive input {
    margin-top: 20px;
    width: 100%;
  }
  .right-top-info-for-profile-div {
    padding-left: 0;
  }
  .profile-picture-for-profinfo img,
  .profile-picture-for-profinfo {
    width: 120px;
    height: 120px;
  }

  .right-top-right-top-info-top-part-of-profinfo-mobile-responsive {
    /* margin-left: 45px; */
  }
  .right-top-profile-div-inputs-mobile-responsive {
    /* width: 270px; */
    padding: 15px 0px 10px 30px;
    font-size: 20px;
  }
  .right-top-profile-div-inputs-mobile-responsive2 {
    margin-top: 15px;
  }
  .top-right-top-info-top-part-of-profinfo-mobile-responsive {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .right-top-info-top-part-of-profinfo-mobile-responsive {
    display: block;
  }
  .middle-main-profinfo-for-profinfo-div,
  .second-middle-main-profinfo-for-profinfo-div,
  .third-middle-main-profinfo-for-profinfo-div,
  .thirds-middle-main,
  .btn-thirds-middle-main {
    width: 100%;
  }
  .info-divs-inside-inputs-and-titles {
    width: 100%;
  }
  .info-divs-inside-inputs-and-titles input {
    width: 100% !important;
    margin-left: 0;
  }
}
@media (max-width: 480px) {
  .right-top-profile-div-inputs-mobile-responsive {
    /* width: 200px; */
    height: 57px;
    font-size: 23px;
  }
  .bottom-right-top-info-top-part-of-profinfo-mobile-responsive, .middle-main-profinfo-for-profinfo-div, .info-divs-inside-inputs-and-titles p {
    padding-left: 10px;
  }
  .bottom-right-top-info-top-part-of-profinfo-mobile-responsive
    .right-top-profile-div-inputs,
  .info-divs-inside-inputs-and-titles input {
    /* width: 410px; */
  }
  .main-content-for-prof-info-wrapper-div {
    padding-left: 0;
  }
}
@media (max-width: 425px) {
  .main-content-for-profile {
    /* height: 106.5vh; */
  }
}
@media (max-width: 411px) {
  .main-content-for-profile {
    /* height: 84.5vh; */
  }
  .bottom-right-top-info-top-part-of-profinfo-mobile-responsive
    .right-top-profile-div-inputs {
    /* width: 362px; */
  }
  .info-divs-inside-inputs-and-titles input {
    /* width: 350px; */
  }
  .info-divs-inside-inputs-and-titles .alone-input-of-profinfo-div {
    /* width: 282px; */
  }
  
  .main-content-for-prof-info-wrapper-div {
    /* padding-bottom: 50px; */
  }
}
@media (max-width: 375px) {
  .right-top-right-top-info-top-part-of-profinfo-mobile-responsive {
    /* margin-left: 7px; */
    width: 100%;
  }

  .main-content-for-prof-info-wrapper-div {
    /* padding-left: 2px; */
  }
  .bottom-right-top-info-top-part-of-profinfo-mobile-responsive
    .right-top-profile-div-inputs {
    /* width: 348px; */
  }
  .bottom-right-top-info-top-part-of-profinfo-mobile-responsive,
  .middle-main-profinfo-for-profinfo-div,
  .info-divs-inside-inputs-and-titles {
    /* width: 353px; */
  }
  .alone-input-of-profinfo-div-outside-div-block button {
    margin-left: 6px;
    padding: 22px 27px 25px 27px;
  }
  .alone-input-of-profinfo-div-outside-div-block .alone-input-of-profinfo-div {
    width: 274px;
  }
  .info-divs-inside-inputs-and-titles-profcard-div-input input,
  .divs-for-prof-passwaord-criteria input {
    width: 303px;
  }
  .main-content-for-profcard-wrapper {
   padding: 20px 0;
  }
  .btn-profcard-middle-main button,
  .bottom-div-for-profpassword-button button {
    width: 303px;
  }
}
