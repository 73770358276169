.photo-one {
   width: 100%;
   padding: 40px;
}

.photo-wrapper-one {
   max-width: 1245px;
   margin: 0 auto;
}
.bnt-answers {
   padding: 20px;
   border: none;
   background: #006aff;
   color: white;
   font-weight: bold;
   border-radius: 10px;
}

.photo-items-one {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   gap: 40px;
}
.photo-items-one textarea {
   min-height: 100px;
   padding: 10px 15px;
   border-color: rgba(0, 0, 0, 0.2);
   background: transparent;
}
.photo-items-one__img {
   width: 100%;
   background-position: center;
   background-size: cover;
}
.bordered-photo-1 {
   border: 1px solid rgba(0, 0, 0, 0.2);
   width: 100%;
   justify-content: center;
   display: flex;
   flex-direction: column;
   position: relative;
}

.bordered-photo-1 .bordered-photo-item {
   justify-content: center;
   text-align: center;
   align-items: center;
   position: relative;
   height: 300px;
   display: flex;
   flex-direction: column;
   padding: 10px;
}

.plus-img {
   position: absolute;
   bottom: 20px;
   right: 20px;
   cursor: pointer;
}
.image-main-full {
   width: 90%;
   height: 90%;
   object-fit: contain;
}
.bordered-photo-1 .bordered-photo-item .question-add {
   width: 100%;
   background: none !important;
   margin-bottom: 0!important;
   padding-bottom: 0 !important;
}
.bordered-photo-1 .bordered-photo-item .question-add img {
   border-radius: 50%;
   background: #006AFF;
   display: flex;
   padding: 20px;
   align-items: center;
   justify-content: center;
}
@media (max-width:769px) {
   .photo-one {
      margin-top: 80px;
   }
}