.question-part {
   width: 100%;
   /*min-height: 100vh;*/
   /*padding: 40px;*/
}

.question-part-conatiner {
   max-width: 1250px;
   margin: 0 auto;
}

.question-part__title {
   font-weight: 400;
   font-size: 20px;
   line-height: 27px;
   margin-bottom: 20px;
}

.question-part-cell {
   margin-top: 20px;
}

.question-part-cell__title {
   font-weight: 400;
   font-size: 16px;
   line-height: 22px;
   color: rgba(0, 0, 0, 0.54);
   margin: 11px 0;
}

.question-box {
   width: 100%;
   background: rgba(217, 217, 217, 0.15);
   border-radius: 10px;
   display: flex;
   justify-content: space-between;
}

.add-text-icon {
   align-self: flex-start;
   margin: 14px 8px;
   cursor: pointer;
}

.question-box-item {
   margin: 26px;
}

@media (max-width:780px) {
   .question-part {
      margin-top: 80px;
   }
}