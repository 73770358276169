.photo-three {
   width: 100%;
   padding: 40px;
}

.photo-wrapper-three {
   max-width: 1245px;
   margin: 0 auto;
}
.header-seelct {
   display: flex;
   justify-content: center;
}
.photo-items-three {
   align-items: center;
   justify-content: center;
   flex-direction: row;
   margin-top: 20px;
}

.photo-items-three .file-3 {
   display: flex;
   justify-content: center;
   flex-direction: column;
   text-align: center;
   align-items: center;
   border: 1px solid rgba(0, 0, 0, 0.2);
   height: 300px;
   width: 100%;
}
.file-3 .image-main-full  {
   width: 90%;
   height: 90%;
   object-fit: contain;
}
.photo-items-three .question-add {
   width: 98px;
   height: 98px;
   border-radius: 50%;
   background: #006AFF;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 20px;
}
.active-bg {
   background: #006aff;
   color: white;
}
.text-3 textarea {
   height: 200px;
   width: 100% !important;
   padding: 20px 20px;
   font-size: 15px;
   background: transparent;
   border: 1px solid rgba(0, 0, 0, 0.2);
}
.photo-items-three__img {
   width: 50%;
}

.photo-items-three__text {
   width: 50%;
   height: 50%;
}

@media (max-width:769px) {
   .photo-three {
      margin-top: 80px;
   }

   .photo-items-three {
      flex-wrap: wrap;
   }
}