.ParolUnut_2_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.ParolUnut_2_block .ParolUnut_2 a {
  text-decoration: none;
}

.ParolUnut_2_block .ParolUnut_2 p {
  color: #757575;
  margin-left: 10px;
  font-size: 18px;
}
.text-for2 {
  color: #757575;
  margin-left: 10px;
  margin-bottom: 50px;
  font-size: 25px!important;
  text-align: center;
}
.ParolUnut_2_block .ParolUnut_2 .ParolUnut_1_block_input {
  background: rgba(17, 17, 17, 0.02);
  border-radius: 24px;
  width: 500px;
}

.ParolUnut_2_block .ParolUnut_2 .ParolUnut_1_block_input input {
  background: #006aff;
  font-size: 22px;
  padding: 16px 20px;
  outline: none;
  background: none;
  border: none;
  width: 100%;
  text-align: center;
  letter-spacing: 20px;
}

.ParolUnut_2_block .ParolUnut_2 .ParolUnut_1_tasdiq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 18px;
}

.ParolUnut_2_block .ParolUnut_2 .ParolUnut_1_tasdiq p {
  cursor: pointer;
  padding: 15px 15px;
  font-size: 16px;
  background: #FAFAFA;
  border-radius: 10px;
}
.ParolUnut_2_block .ParolUnut_2 .ParolUnut_1_tasdiq p:hover {
  background: #E7F0FF;
  border: none;
  outline: none;
  color: #006aff;
  border-radius: 10px;
  padding: 15px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.6s;
}
.ParolUnut_2_block .ParolUnut_2 .ParolUnut_1_tasdiq button {
  background: #E7F0FF;
  border: none;
  outline: none;
  color: #006aff;
  border-radius: 10px;
  padding: 15px 15px;
  font-size: 16px;
  cursor: pointer;
}
.ParolUnut_2_block .ParolUnut_2 .ParolUnut_1_tasdiq button:hover {
  padding: 15px 15px;
  background: #FAFAFA;
  color: #757575;
  font-size: 16px;
  transition: 0.6s;
}


@media (max-width: 550px) {
  .ParolUnut_2_block .ParolUnut_2 {
    width: 90%;
  }
  .ParolUnut_2_block .ParolUnut_2 .ParolUnut_1_block_input {
    width: 100%;
  }
  .ParolUnut_2_block .ParolUnut_2 .ParolUnut_1_block_input input {
    width: 180px;
  }
}
