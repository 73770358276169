.photo-two {
   width: 100%;
   padding: 40px;
}

.photo-wrapper-two {
   max-width: 1245px;
   margin: 0 auto;
}
.w-50 {
   width: 50%;
}
.photo-items-two .bordered-photo-item {
   flex-direction: column;
   display: flex;
   justify-content: center;
   position: relative;
   text-align: center;
   align-items: center;
   border: 1px solid rgba(0 ,0, 0, 0.2);
   height: 300px;
}
.photo-items-two .bordered-photo-item .image-main-full {
   width: 60%;
   height: 90%;
   object-fit: contain;
}
.textarea-answer-2 textarea {
   height: 300px;
   background: transparent;
   border-color: rgba(0, 0, 0, 0.2);
   padding: 10px 10px;
}
.photo-items-two {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   gap: 40px;
}
.div-btn {
   display: flex;
   justify-content: center;
   text-align: center;
   align-items: center;
   margin-top: 20px;
}
.photo-items-two__img {
   width: 50%;
}

.photo-items-two__text {
   width: 50%;
   height: 50%;
}

@media (max-width:769px) {
   .photo-two {
      margin-top: 80px;
   }

   .photo-items-two {
      flex-wrap: wrap;
   }
}