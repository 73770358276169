.ParolUnut_3_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.ParolUnut_3_block .ParolUnut_3 a {
  text-decoration: none;
}
.loginPage-inner-icon-for {
  transform: translateY(-46px) translateX(441px) ;
  width: 30px;
  border: none;
  background: transparent;
  cursor: pointer;
}
.loginPage-inner-icon-for img{
  width: 30px;
}
.loginPage-inner-icon-for{
  height: 32px;
  display: flex;
  align-items: center;
}
.loginPage-inner-icon-for img{
  width: 20px;
  height: 17px;
  opacity: 50%;
}
.ParolUnut_3_block .ParolUnut_3 h5 {
  color: #757575;
  text-align: end;
  margin-right: 6px;
  cursor: pointer;
  padding: 5px;
  font-weight: 500;
}

.ParolUnut_3_block .ParolUnut_3 p {
  color: #757575;
  margin-left: 10px;
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 25px;
}

.ParolUnut_3_block .ParolUnut_3 .ParolUnut_1_block_input {
  background: rgba(17, 17, 17, 0.02);
  border-radius: 24px;
  width: 500px;
}

.ParolUnut_3_block .ParolUnut_3 .ParolUnut_1_block_input input {
  font-size: 18px;
  padding: 20px 20px;
  outline: none;
  background: none;
  border: none;
  width: 100%;
}

.ParolUnut_3_block .ParolUnut_3 .ParolUnut_1_tasdiq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 18px;
  margin-top: 30px;
}

.ParolUnut_3_block .ParolUnut_3 .ParolUnut_1_tasdiq p {
  cursor: pointer;
  padding: 15px 15px;
  font-size: 16px;
  background: #FAFAFA;
  border-radius: 10px;
  margin: 0;
}
.ParolUnut_3_block .ParolUnut_3 .ParolUnut_1_tasdiq p:hover {
  background: #E7F0FF;
  border: none;
  outline: none;
  color: #006aff;
  border-radius: 10px;
  padding: 15px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.6s;
}
.ParolUnut_3_block .ParolUnut_3 .ParolUnut_1_tasdiq button {
  background: #E7F0FF;
  border: none;
  outline: none;
  color: #006aff;
  border-radius: 10px;
  padding: 15px 15px;
  font-size: 16px;
  cursor: pointer;
}
.ParolUnut_3_block .ParolUnut_3 .ParolUnut_1_tasdiq button:hover {
  padding: 15px 15px;
  background: #FAFAFA;
  color: #757575;
  font-size: 16px;
  transition: 0.6s;
}
@media (max-width: 550px) {
  .ParolUnut_3_block .ParolUnut_3 {
    width: 90%;
  }
  .ParolUnut_3_block .ParolUnut_3 .ParolUnut_1_block_input {
    width: 100%;
  }
  .ParolUnut_3_block .ParolUnut_3 .ParolUnut_1_block_input input {
    width: 180px;
  }
}
