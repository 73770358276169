.photo-four {
   width: 100%;
   padding: 40px;
}

.photo-wrapper-four {
   max-width: 1245px;
   margin: 0 auto;
}

.photo-items-four__imgs {
   display: flex;
   justify-content: space-around;
   width: 100%;
}
.photo-items-four {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   gap: 40px;
}

.photo-items-four__img {
   width: 20%;
}
.file-4  {
   text-align: center;
   align-items: center;
   position: relative;
   display: flex;
   justify-content: center;
   flex-direction: column;
   border: 1px solid rgba(0, 0, 0, 0.2);
   width: 23%;
   height: 200px;
   border-radius: 10px;
}

.file-4 .image-main-full {
   width: 90%;
   height: 90%;
   object-fit: contain;
}

.text-area-4 {
   height: 200px;
   background: transparent;
   font-size: 15px;
   padding: 20px;
   border-color: rgba(0, 0, 0, 0.2);;
}
@media (max-width:769px) {
   .photo-four {
      margin-top: 80px;
   }

   .photo-items-four__img {
      width: 25%;
   }

   .photo-items-four__text {
      font-size: 18px;
   }

   .photo-items-four {
      flex-wrap: wrap;
   }
}