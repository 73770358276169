.dropdown-menu-video {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
  background-color: rgba(17, 17, 17, 0.03);
}
.dropdown-menu-video h3 {
  color: black;
  border-radius: 5px;
  cursor: pointer;
  padding: 20px;
  box-shadow: 0 0 15px -3px rgba(17, 17, 17, 0.03);
}
.dropdown-menu-video .dropdown-item {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-bottom: 1px dashed black;
  justify-content: space-between;
}
.dropdown-menu-video .main-drop {
  display: none;
}
.dropdown-menu-video .main-drop.show {
  display: block;
}
.bordered-img {
  background-color: #FFF0A0;
  border-radius: 50%;
  padding: 10px;
}
.dropdown-menu-video .dropdown-item .name {
  display: flex;
  text-align: center;
  align-items: center;
  width: 90%;
  cursor: pointer;
}
.dropdown-menu-video .dropdown-item .name p {
  word-break: break-all;
  margin-left: 10px;
}
.dropdown-menu-video .dropdown-item .actions {
  width: 50px;
  display: flex;
  justify-content: space-between;
}
.div-upload-video-file-test {
  width: 205px !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModal__Overlay {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.ReactModal__Content,
.ReactModal__Content--after-open {
  position: inherit !important;
  max-height: 100% !important;
  top: 0 !important;
  left: auto !important;
  right: auto !important;
  bottom: 0 !important;
}

.curses-div-from-teacher_plus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 330px;
  width: 250px;
  border: 3px dashed #006aff;
  border-radius: 24px;
}

.curses-div-from-teacher_plus span {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 85px;
  width: 80px;
  border-radius: 50%;
  border: 3px solid #006aff;
  color: #006aff;
}
.curses-div-from-teacher_plus h3 {
  margin-top: 30px;
  color: #006aff;
  cursor: pointer;
}

.main-content-for-eduon-courses {
  padding: 10px;
}
.main-content-for-eduon-courses::-webkit-scrollbar {
  display: none;
}
.curses-div-from-teacher {
  width: 252px;
}

.img-div-course-div {
  position: relative;
}

.curses-div-from-teacher .img-div-course-div img {
  border-radius: 18px;
  max-height: 167px;
  height: 167px;
  object-fit: cover;
  box-shadow: 0 2px 5px 0 rgba(50, 50, 105, 0.15),
    0 1px 1px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.curses-div-from-teacher .img-div-course-div .confirm-img {
  width: 35px;
  height: max-content;
  background-color: white;
  padding: 8px;
  border-radius: 16px;
}
.status_img {
  position: absolute;
  bottom: 12px;
  right: 12px;
}
.uploaded-courses-from-teacher {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  justify-content: space-around;
  margin-top: 18px;
  padding-bottom: 40px;
}
.uploaded-courses-div-paragraph-element {
  padding-top: 15px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #303030;
}
.course-title-from-courses-div {
  margin-top: 18px;
  width: 196px;
  width: 100%;
  height: 38px;
  /* padding-left: 6px; */
}
.course-title-from-courses-div span {
  font-size: 16px;
  line-height: 19px;
  color: rgba(17, 17, 17, 0.72);
  font-family: Gilroy-Medium;
}
.course-price-rating-comment-number-show-div {
  margin-top: 13px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(17, 17, 17, 0.06);
  padding-bottom: 18px;
}
.course-rating-of-teacher {
  font-size: 14px;
  line-height: 16px;
  color: #006aff;
  display: flex;
  align-items: center;
  font-family: GIlroy-Medium;
}
.course-rating-of-teacher img {
  margin-right: 6px;
}
.course-rating-of-teacher span {
  color: #006aff80;
}
.course-sold-indicator {
  margin-left: 18px;
  font-size: 14px;
  color: #006aff;
  display: flex;
  align-items: center;
  font-family: Gilroy-Medium;
}
.course-sold-indicator img {
  margin-right: 6px;
}
.course-comments-from-students {
  margin-left: 18px;
  font-size: 14px;
  color: #006aff;
  display: flex;
  font-family: Gilroy-Medium;
  align-items: center;
}
.course-comments-from-students img {
  margin-right: 6px;
}
.course-price-and-editing-button-show-div {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px 0px 5px;
}
.course-price-and-editing-button-show-div span {
  font-size: 16px;
  line-height: 19px;
  color: rgba(17, 17, 17, 0.72);
  font-family: Gilroy-Medium;
}
.inside-span-sum {
  color: rgba(17, 17, 17, 0.54);
}
.course-price-and-editing-button-show-div button {
  padding: 12px 22px 12px 22px;
  background-color: rgba(17, 17, 17, 0.06);
  font-family: Gilroy-Medium;
  border-radius: 12px;
  border: none;
  outline: none;
  font-size: 16px;
  color: rgba(17, 17, 17, 0.54);
}
.uploaded-courses-div-paragraph-element2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #111111;
  margin-top: 24px;
}
.new-courses-from-teacher {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 21px;
  margin-top: 18px;
}
.new-course-div-from-teacher {
  width: 252px;
}
.top-new-course-div img {
  width: 252px;
}
.title-content-of-new-course-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-content-of-new-course-div p {
  font-size: 16px;
  font-family: Gilroy-Medium;
  display: flex;
  align-items: center;
  color: rgba(17, 17, 17, 0.72);
  width: 239px;
  height: 40px;
}
.title-content-of-new-course-div button {
  margin-top: 18px;
  padding: 12px 73px 12px 73px;
  background: rgba(17, 17, 17, 0.06);
  border-radius: 12px;
  font-size: 16px;
  color: rgba(17, 17, 17, 0.54);
  outline: none;
  border: none;
}
button a {
  text-decoration: none;
  font-size: 16px;
  color: rgba(17, 17, 17, 0.54);
}
.one-course-content-by-id {
  padding: 33px 52px 0px 30px;
}
.top-course-elements-to-editing-and-uploading {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 18px;
}
.div-upload-video-file-test {
  width: 254px;
  height: 84px;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
  padding: 18px 0px 30px 24px;
  cursor: pointer;
}
.div-upload-video-file-test button {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
}
.div-upload-video-file-test button span {
  margin-left: 16px;
  font-size: 16px;
  color: #111111;
}
.course-structure-title {
  margin-top: 36px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #111111;
}
.main-video-spiker {
  display: flex;
  flex-direction: column;
}
.uploaded-course-file-inside-div {
  margin-top: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
}
.play-course-button {
  width: 50px;
  height: 50px;
  background-color: #e7f0ff;
  border-radius: 50%;
}
.play-course-button svg {
  /*padding: 5px;*/
}
.container-video {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
.video-player {
  width: 100%;
  max-width: 700px;
  max-height: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.plyr--video {
  width: 100% !important;
  height: 100% !important;
}
.exit-btn {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: right;
  cursor: pointer;
}

.img-div-element-div {
  width: 250px !important;
  height: 140px;
  position: relative;
}
.img-test {
  width: 250px !important;
  height: 140px;
  position: relative;
}
.img-test img{
  border-radius: 15px;
  height: 100%;
  width: 100%;
  padding: 20px;
  object-fit: contain;
  background: #dce7f7;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.img-div-element-div img {
  border-radius: 15px;
  height: 100%;
  width: 50px;
  object-fit: cover;
  background: #dce7f7;
}
.course-title-div-element-div {
  margin-top: 18px;
}
.course-title-div-element-div span {
  font-size: 16px;
  line-height: 19px;
  color: rgba(17, 17, 17, 0.72);
}
.course-parametres-for-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 19px;
}
.left-side-course-parametres {
  display: flex;
  align-items: center;
}
.left-side-course-parametres div,
.left-side-course-parametres span {
  margin: 0px 8px;
}
.right-side-course-parametres {
  display: flex;
  align-items: center;
}
.right-side-course-parametres div {
  margin: 0px 10px;
}
.uploaded-file-course-inside-div-element {
  width: 252px;
}
.file-name-of-parametres {
  width: 252px;
  height: 168px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 10px;
  font-size: 16px;
  color: #111111;
  border-bottom: 2px solid rgba(17, 17, 17, 0.06);
}
.file-title-of-file-course {
  margin-top: 18px;
}
.file-title-of-file-course span {
  font-size: 16px;
  color: rgba(17, 17, 17, 0.72);
}
.uploaded-file-course-inside-div-element,
.uploaded-video-course-inside-div-element {
  border-bottom: 2px solid rgba(17, 17, 17, 0.06);
  padding-bottom: 18px;
}
.variant-3 {
  width: 100%;
}
.left-course-type {
  margin-top: 18px;
}

#video {
  display: none;
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.modal-upload-video {
  position: fixed;
  top: 40%;
  left: 40%;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 200px;
  z-index: 10000000;
  border-radius: 10px;
  background-color: white;
  box-shadow: 10px 10px 10px 10px white;
}
.loader-video-modal {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 20px;
}
.loader-video-modal p {
  margin-bottom: 30px;
  font-weight: 600;
}
.loader-video-modal div .sk-chase {
  width: 90px;
  height: 90px;
}
.loader-video-modal div p {
  position: absolute;
  top: 50%;
  left: 42%;
  width: 60px;
  font-size: 20px;
}
.loader-video-modal div .sk-chase-dot:before {
  width: 20%;
  height: 20%;
}
#image {
  display: none;
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.video-photo-block
  .photo-block
  .text-block
  .input-photo
  .video-cover-div-course {
  width: 30%;
}
.course-image-file {
  display: flex;
  flex-direction: column;
}
.course-file-name-label {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.course-file-name-div {
  padding: 24px;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
  width: 390px;
  height: 76px;
  margin-right: 12px;
}
.course-file-name-div::-webkit-scrollbar {
  width: 0px;
  display: none;
}
.course-file-name {
  margin-left: 0;
  font-family: Gilroy-Medium;
  font-size: 24px;
  color: #111111;
}
.choose-type-video {
  display: flex;
}
.choose-type-video input {
  margin-left: 10px;
}
.video-cover-div {
  padding: 22px 22px;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
}
.top-modal-file-upload-from-teacher-div-input {
  padding: 24px 140px 24px 24px;
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
  outline: none;
  border: none;
  font-family: Gilroy-Medium;
  font-size: 24px;
  color: #111111;
  margin-top: 12px;
}
.midlle-modal-file-upload-from-teacher-div {
  margin-top: 18px;
}
.midlle-modal-file-upload-from-teacher-div p,
.top-modal-file-upload-from-teacher-div p {
  font-family: Gilroy-Medium;
  font-size: 16px;
  line-height: 19px;
  color: rgba(17, 17, 17, 0.3);
}

.test-modal {
  display: flex;
  justify-content: space-between;
}
.test-modal div {
  /*width: 33%;*/
}
.test-modal div input {
  width: 33%;
}
.test-modal-input {
  background: rgba(17, 17, 17, 0.03);
  width: 100% !important;
  padding: 24px 0 24px 24px;
  border-radius: 18px;
  outline: none;
  border: none;
  font-family: Gilroy-Medium;
  font-size: 24px;
  color: #111111;
  margin-top: 12px;
}
@media (max-width: 2000px) {
  .img-div-element-div img {
    width: 250px;
  }
  .img-div-course-div img {
    width: 250px;
  }
}
@media (max-width: 1440px) {
  .uploaded-courses-from-teacher {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1280px) {
  .img-div-element-div img {
    width: 250px;
  }
  .hidden-in-1280 {
    display: none;
  }
  .modal-upload-video {
    left: 36%;
  }
}

@media (max-width: 1024px) {
  .curses-div-from-teacher,
  .new-course-div-from-teacher {
    width: 245px;
  }
  .uploaded-courses-from-teacher {
    grid-template-columns: 1fr 1fr;
  }
  .div-upload-video-file-test {
    width: 245px;
  }
  .uploaded-video-course-inside-div-element,
  .uploaded-file-course-inside-div-element {
    width: 240px;
  }
  .img-div-element-div img {
    width: 238px;
  }
  .modal-upload-video {
    left: 35%;
  }
  .loader-video-modal div p {
    top: 52%;
    left: 42%;
  }
}
@media (max-width: 768px) {
  .modal-upload-video {
    left: 30%;
  }

  .main-content-for-eduon-courses {
    margin: 100px 0 10px 0;
    padding: 0;
  }

  .uploaded-courses-from-teacher {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-menu-section-links .main-menu-section-links5 {
    display: none;
  }
  .main-menu-to-sections {
    z-index: 1000;
    position: fixed;
    left: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
  }
  .uploaded-courses-from-teacher {
    grid-template-columns: 1fr 1fr;
  }
  .new-courses-from-teacher {
    grid-template-columns: 1fr 1fr;
  }
  .one-course-content-by-id {
    height: 88vh;
    margin-top: 70px;
  }
  .top-course-elements-to-editing-and-uploading {
    grid-template-columns: 1fr 1fr;
  }
  .uploaded-course-file-inside-div {
    grid-template-columns: 1fr 1fr;
  }
  .hidden-in-768 {
    display: none;
  }
}
@media (max-width: 540px) {
  #jvlabelWrap {
    bottom: 140px !important;
  }
  .ref-menu {
    display: none;
  }
  .curses-div-from-teacher {
    width: 230px;
  }
  .img-div-course-div img {
    width: 230px;
  }
  .course-price-and-editing-button-show-div button {
    padding: 8px 10px;
  }
  .course-price-and-editing-button-show-div button a {
    font-size: 14px;
  }
  .new-course-div-from-teacher,
  .top-new-course-div img {
    width: 230px;
  }
  .title-content-of-new-course-div div p {
    font-size: 15px;
    width: 230px;
  }
  .title-content-of-new-course-div button {
    padding: 8px 50px;
  }
  .div-upload-video-file-test {
    padding: 12px 0px 20px 18px;
    height: 75px;
    width: 230px;
  }
  .modal-upload-video {
    width: 250px;
    height: 180px;
    left: 25%;
  }
  .loader-video-modal div p {
    top: 55%;
    left: 40%;
  }
}
@media (max-width: 480px) {
  .uploaded-courses-from-teacher {
    grid-template-columns: 1fr;
  }
  .curses-div-from-teacher {
    width: 75%;
    margin: 20px 0;
  }
  .video-player {
    max-width: 330px;
    max-height: 260px;
  }
  .img-div-course-div img {
    width: 100%;
  }
  .hidden-in-480 {
    display: none;
  }
  .course-title-from-courses-div {
    width: 100%;
  }
  .course-price-and-editing-button-show-div button a {
    font-size: 20px;
  }
  .new-courses-from-teacher {
    grid-template-columns: 1fr;
  }
  .new-course-div-from-teacher {
    width: 95%;
  }
  .top-new-course-div img,
  .title-content-of-new-course-div div {
    width: 100%;
  }
  .title-content-of-new-course-div div p {
    width: 100%;
    font-size: 20px;
    font-family: Gilroy-Medium;
  }
  .title-content-of-new-course-div-button-div button {
    width: 100%;
    height: 50px;
    font-family: Gilroy-Medium;
  }
  .top-course-elements-to-editing-and-uploading {
    grid-template-columns: 1fr;
  }
  .div-upload-video-file-test {
    width: 100%;
    height: 84px;
    display: flex;
    align-items: center;
    padding: 20px 0px 20px 18px;
  }
  .uploaded-course-file-inside-div {
    grid-template-columns: 1fr;
  }
  .uploaded-video-course-inside-div-element,
  .uploaded-file-course-inside-div-element {
    width: 100%;
  }
  .img-div-element-div img {
    width: 100%;
  }
  .modal-upload-video {
    width: 250px;
    height: 180px;
    left: 22%;
  }
}
@media (max-width: 411px) {
  .curses-div-from-teacher,
  .new-course-div-from-teacher {
    width: 84%;
  }
  .div-upload-video-file-test,
  .uploaded-video-course-inside-div-element,
  .uploaded-file-course-inside-div-element {
    width: 90%;
  }
  .top-modal-file-upload-from-teacher-div-input {
    font-size: 18px;
    width: 285px;
    padding-right: 10px;
  }
  .modal-upload-video {
    width: 250px;
    height: 180px;
    top: 40%;
    left: 16%;
  }
  .loader-video-modal div p {
    top: 55%;
    left: 40%;
  }
}
@media (max-width: 375px) {
  .curses-div-from-teacher,
  .new-course-div-from-teacher {
    width: 100%;
  }
  .div-upload-video-file-test {
    width: 100%;
  }
  .uploaded-video-course-inside-div-element,
  .uploaded-file-course-inside-div-element {
    width: 100%;
  }
}
@media (max-width: 360px) {
  .main-content-for-teachers {
    /* height: 470px; */
  }
}
