@font-face {
  font-family: "Gilroy-Medium";
  src: url(../../fonts/Gilroy-Medium.ttf);
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url(../../fonts/Gilroy-Regular.ttf);
}
.videos_spiker {
  margin-left: 20px;
  margin-right: 20px;
}
.modal-content {
  max-width: 350px;
  max-height: 350px;
  width: 400px;
  background: #fff;
  border: none!important;
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  border-radius:10px;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
}
.modal-content h4 {
  font-size: 20px;

}
.modal-content button {
  border: none;
  background: #006aff;
  color: #fff;
  padding: 1rem;
  font-size: 16px;
  border-radius: 13px;
  margin-top: 2rem;
}
p {
  user-select: none !important;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
span {
  user-select: none !important;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

div {
  user-select: none !important;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.add-course-container {
  padding-top: 10px;
  /* padding-left: 10px; */
  margin-bottom: 100px;
}

.course-header-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* position: sticky; */
  top: 0;
  background: white;
  z-index: 3;
  transition: 0.2s;
}

.course-header-links .header-links {
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 17px;
  margin-left: 15px;
  background: rgba(17, 17, 17, 0.03);
  width: 254px;
  height: 74px;
  padding-left: 10px;
  transition: 0.2s;
  cursor: pointer;
}
.course-header-links .header-links input {
  display: none;
}
.active-circle {
  background: #006aff !important;
}

.active-header-steps {
  border: 1px solid #006aff;
}

.active-header-steps .name-link {
  color: #111111 !important;
  /*color: red !important;*/
}

.active-header-steps .number-links {
  background: rgba(0, 106, 255, 0.4) !important;
  opacity: 1 !important;
}

.active-header-steps .number-links span {
  color: rgba(0, 106, 255, 1) !important;
}
.loader-to-add-course {
  position: fixed;
  top: 40%;
  left: 40%;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 200px;
  z-index: 10000000;
  border-radius: 10px;
  background-color: white;
  box-shadow: 10px 10px 10px 10px white;
}
.image-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 20px;
}
.course-header-links .header-links .number-links {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(162, 162, 162, 0.15);

  /*opacity: 0.2;*/
}

.course-header-links .header-links .number-links span {
  color: rgba(162, 162, 162, 0.7);
  font-size: 30px;
  font-family: Gilroy-Medium;
}

.course-header-links .header-links .name-link {
  font-size: 14px;
  line-height: 21px;
  color: #a2a2a2;
  /*margin-left: 20px;*/
  font-family: Gilroy-Medium;
  margin-left: 20px;
  font-weight: 600;
}

.success-icon {
  width: 10px;
  height: 10px;
  background: rgba(162, 162, 162, 0.8);
  margin-left: 10px;
  border-radius: 50px;
}

.add-course-content {
  width: 100%;
  padding: 30px;
  /* padding-top: 30px; */
}

.add-course-content .course-main-title {
  font-size: 25px;
  color: #006aff;
  font-family: Gilroy-Medium !important;
}

.add-course-content .course-main-title2 {
  font-size: 20px;
  font-family: Gilroy-Regular !important;
}

.add-course-content .name-course-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

.add-course-content .name-course-block p {
  margin-bottom: 10px;
  font-size: 17px;
  color: #000000;
  font-family: Gilroy-Regular !important;
}
.add-course-content .name-course-block div {
  display: flex;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
}
.add-course-content .name-course-block div input,
textarea {
  height: 60px;
  border-radius: 17px;
  width: 100%;
  background: #f8f9fb;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #006aff;
  outline: none;
  resize: none;
  padding-left: 20px;
}
.add-course-content .name-course-block div p {
  position: absolute;
  right: 10px;
  background: #006aff;
  color: white;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-align: center;
  height: 30px;
  border-radius: 10px;
}
.add-course-content .name-course-block input::-webkit-input-placeholder {
  color: #a2a2a2;
}

.add-course-content .name-course-block .input-desc {
  min-height: 100px;
  border-radius: 17px;
  background: #f8f9fb;
  box-sizing: border-box;
  border: 1px solid #006aff;
  outline: none;
  padding: 10px 20px;
}

.add-course-content .block-next-selects {
  width: 50%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
  padding: 10px;
  flex-direction: row;
  justify-content: space-evenly;
}
.block-next-selects .selects-block {
  margin-bottom: 35px;
}
.add-course-content .block-next-selects .selects-block p {
  margin-bottom: 10px;
  font-size: 18px;
  color: #000000;
  font-family: Gilroy-Regular !important;
}

.add-course-content .block-next-selects .selects-block select {
  border: 1px solid #006aff;
  background: #f8f9fb;
  box-sizing: border-box;
  border-radius: 17px;
  width: 200px;
  height: 60px;
  padding-left: 10px;
  /* margin-bottom: 20px; */
  outline: none;
  font-family: Gilroy-Regular !important;
  text-transform: capitalize;
}
.first {
  width: 50%;
  /* padding: 10px; */
  padding-top: 10px;
}
.firstBlock {
  width: 100%;
  display: flex;
}
.d-smoothy {
  display: block;
  animation-name: smooth;
  animation-duration: 0.8s;
  animation-timing-function: all linear;
  color: #7e7e7e;
}
@keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.d-none {
  display: none !important;
}
.selects-block-alone {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-end;
}
.infoAbout {
  height: 18px !important;
  width: max-content;
  margin: 0 !important;
  padding: 0 !important;
}
.infoAbout img {
  height: 18px !important;
  display: block !important;
  width: 18px !important;
  margin-left: 10px;
  fill: #006aff;
  cursor: pointer;
}
.video-photo-block {
  width: 50%;
  display: flex;
  /* padding-top: 20px; */
  flex-direction: column;
}

.video-photo-block .photo-block {
  /* display: flex; */
  justify-content: space-between;
  /* padding-top: 15px; */
  margin-bottom: 15px;
}

.video-photo-block .photo-block .image-block {
  width: 35%;
}

.video-photo-block .photo-block .image-block p {
  font-family: Gilroy-Regular !important;
  font-size: 18px;
  display: flex;
}

.video-photo-block .photo-block .image-block img {
  width: 100%;
  height: 100%;
  display: none;
}

.video-photo-block .photo-block .text-block {
  width: 100%;
  font-family: Gilroy-Regular !important;
}

.video-photo-block .photo-block .text-block p {
  align-items: start;
  text-align: start;
  color: #5e5e5e;
  line-height: 25.78px;
  font-size: 17px;
  font-family: Gilroy-Regular !important;
}
.video-photo-block .photo-block .text-block .input-photo input {
  width: 70%;
}
.video-photo-block .photo-block .text-block input {
  background: #f8f9fb;
  border: 1px solid #006aff;
  box-sizing: border-box;
  border-bottom-left-radius: 17px;
  border-top-left-radius: 17px;
  outline: none;
  padding: 18px;
  width: 30%;
  border-right: none;
  font-size: 18px;
  font-weight: 300;
}
.choose-type-of-video {
  display: flex;
  /* justify-content: space-evenly; */
}
.link-input input {
  width: 100% !important;
  border-right: 1px solid #006aff80 !important;
  border-radius: 15px !important;
  margin-top: 20px !important;
}
.choose-type-of-video div {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: center;
  text-align: right;
  margin-top: 10px;
  margin-right: 20px;
}
.choose-type-of-video div input {
  width: 50px !important;
  height: 20px !important;
}
.choose-type-of-video div span {
  font-family: Gilroy-Regular !important;
}
.video-photo-block .photo-block .text-block .input-photo {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

.video-photo-block
  .photo-block
  .text-block
  .input-photo
  .video-cover-div-course {
  outline: none;
  text-align: center;
  padding: 20px 14px;
  border-bottom-right-radius: 17px;
  border-top-right-radius: 17px;
  border-left: none;
  background: #98c2fe;
  cursor: pointer;
}

.video-photo-block
  .photo-block
  .text-block
  .input-photo
  .video-cover-div-course
  label {
  text-align: center;
  align-items: center;
  color: #006aff;
  cursor: pointer;
  font-family: Gilroy-Regular !important;
}

.last-block {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.last-block p {
  color: #000000;
  font-size: 20px;
  font-family: Gilroy-Medium !important;
  display: flex;
  align-items: center;
}
.MuiAutocomplete-hasClearIcon {
  width: 100%;
}

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background: #f8f9fb;
  border-radius: 15px;
  padding: 10px !important;
  border: 1px solid #006aff;
}
.Mui-focused,
.MuiFormControl-fullWidth:hover,
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background: #f8f9fb !important;
}
.MuiFilledInput-underline:after,
.MuiFilledInput-underline:before {
  display: none;
  border: none;
}
.last-block span {
  color: #000000;
  font-size: 14px;
  /* padding-top: 10px; */
  font-family: Gilroy-Regular !important;
}
.last-block > div {
  display: flex;
  position: relative;
  margin-top: 10px;
  width: 50%;
}
.last-block div p {
  position: absolute;
  right: 2%;
  top: 25%;
  background: #006aff;
  color: white;
  width: 30px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 30px;
  border-radius: 10px;
}
.last-block div textarea {
  height: 70px;
  margin-bottom: 50px;
  border-radius: 17px;
  border: 1px solid #006aff;
  outline: none;
  padding: 15px;
  font-size: 16px;
}

.next-btn {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  align-items: end;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
}

.next-btn div {
  border: 1px solid #006aff;
  background-color: #006aff;
  display: flex;
  padding: 15px;
  outline: none;
  color: #fff;
  border-radius: 17px;
  transition: 0.2s;
}
.back-btn img {
  transform: rotate(90);
}
.next-btn div img {
  margin-left: 5px;
  width: 25px;
}

.opacity-none {
  opacity: 0;
  display: none;
}
.back-btn {
  color: #ffff !important;
}
.back-btn:hover {
  background-color: #98c2fe !important;
  color: #ffff !important;
}
.titleOfBlock {
  display: flex;
  align-items: center;
}

.next-btn div:hover {
  background: #98c2fe;
  border: 1px solid #98c2fe;
  outline: none;
}

.second-form {
  margin-top: 40px;
  padding-left: 30px;
  margin-bottom: 20px;
  width: 50%;
}

.second-form p,
span {
  line-height: 24px;
}

.second-form .header-title {
  color: #006aff;
  font-size: 22px;
  font-family: Gilroy-Medium !important;
}

.second-form .header-title2 {
  padding-top: 5px;
  font-size: 17px;
  color: #000000;
  font-family: Gilroy-Regular !important;
}

.second-form .second-form-first-block {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.second-form .second-form-first-block .input-second-block-2,
.second-form .second-form-first-block .input-second-block-3 {
  display: flex;
  position: relative;
  text-align: center;
}
.second-form .second-form-first-block .input-second-block-2 input,
.second-form .second-form-first-block .input-second-block-3 input {
  margin-top: 10px;
  background: #f8f9fb;
  border: 1px solid #006aff;
  outline: none;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 10px;
  width: 100%;
  height: 60px;
}
.second-form .second-form-first-block p {
  font-size: 18px;
  color: #000000;
  font-family: Gilroy-Medium !important;
}

.second-form .second-form-first-block span {
  font-size: 15px;
  color: #000000;
  padding-top: 5px;
  font-family: Gilroy-Regular !important;
}
.second-form .second-form-first-block .input-second-block {
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  width: 100%;
}
.second-form .second-form-first-block .input-second-block p,
.second-form .second-form-first-block .input-second-block-2 p,
.second-form .second-form-first-block .input-second-block-3 p {
  position: absolute;
  right: 1%;
  width: 35px;
  top: 28%;
  padding-top: 2px;
  font-size: 15px;
  color: white;
  border-radius: 7px;
  height: 30px;
  background: #006aff;
}
.second-form .second-form-first-block .input-second-block input {
  margin-top: 10px;
  background: #f8f9fb;
  border: 1px solid #006aff;
  outline: none;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 10px;
  width: 100%;
  height: 60px;
}

.add-input {
  display: flex;
  align-items: center;
  padding-top: 10px;
  cursor: pointer;
  padding-left: 10px;
}

.add-input div {
  border: 1px solid #006aff;
  width: 20px;
  height: 20px;
  padding-left: 4px;
}

.add-input div img {
  width: 10px;
  align-items: center;
  text-align: center;
}

.add-input p {
  font-size: 17px !important;
  padding-left: 5px;
  font-family: Gilroy-Medium !important;
}

.third-block-course {
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 20px;
}

.third-block-course .main-title-3block {
  color: #006aff;
  font-size: 22px;
  line-height: 29px;
  font-family: Gilroy-Medium !important;
}

.third-block-course .main-title-3block2 {
  color: #000000;
  font-size: 15px;
  font-family: Gilroy-Regular !important;
}

.third-block-course .block3-1div {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.third-block-course .block3-1div p {
  font-size: 22px;
  color: #000000;
  font-weight: 500;
  padding-bottom: 5px;
  font-family: Gilroy-Medium !important;
}

.third-block-course .block3-1div span {
  font-size: 16px;
  width: 80%;
  padding-bottom: 10px;
  font-family: Gilroy-Regular !important;
}

.third-block-course .block3-1div div {
  display: flex;
  position: relative;
  align-items: center;
}

.third-block-course .block3-1div div p {
  position: absolute;
  right: 52%;
  font-size: 16px;
  font-family: Gilroy-Regular !important;
}

.third-block-course .block3-1div input {
  width: 100%;
  max-width: 540px;
  background: #f8f9fb;
  border: 1px solid #006aff;
  box-sizing: border-box;
  border-radius: 17px;
  font-size: 17px;
  outline: none;
  padding: 20px;
}

.third-block-course .block3-2div {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.third-block-course .block3-2div p {
  font-size: 22px;
  color: #000000;
  /*font-weight: 500;*/
  padding-bottom: 5px;
  font-family: Gilroy-Medium !important;
}

.third-block-course .block3-2div span {
  font-size: 16px;
  width: 80%;
  padding-bottom: 10px;
  font-family: Gilroy-Regular !important;
}

.third-block-course .block3-2div label {
  padding: 20px 18px;
  border: 1px solid #006aff !important;
  max-width: 540px;
  background: #f8f9fb;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.third-block-course .block3-2div label p {
  font-size: 15px;
  color: #000000;
  font-family: Gilroy-Regular !important;
}

.third-block-course .block3-2div label input {
  border-radius: 20px;
  width: 20px;
  height: 20px;
}

.block3-3div {
  display: flex;
  justify-content: space-around;
}

.block3-3div .text-div-sert {
  margin-top: 100px;
}

.block3-3div .text-div-sert div {
  display: flex;
  align-items: center;
  text-align: center;
}

.block3-3div .text-div-sert div img {
  padding-left: 30px;
}

.block3-3div .text-div-sert .top-sert {
  margin-top: 40px;
}

.block3-3div .text-div-sert .footer-sert {
  margin-top: 80px;
}

.block3-3div .text-div-sert p {
  color: #006aff;
  font-size: 25px;
  font-family: Gilroy-Regular !important;
}

.block3-3div .text-div-sert button {
  margin-top: 30px;
  text-align: center;
  align-items: center;
  width: 150px;
  color: #006aff;
  height: 40px;
  background: #e7f0ff;
  border-radius: 15px;
  outline: none;
  border: none;
}

.third-block-course .image-sertificate {
  width: 700px;
  height: 400px;
  border: 1px solid #006aff;
  background: #006aff;
}

.image-sertificate {
  margin-top: 20px;
}

.footer-content-sert {
  display: flex;
}

.footer-content-sert svg {
  width: 50px;
  height: 50px;
  margin-top: 230px;
  margin-left: 20px;
}

.image-sertificate div .logo-content-sert {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-sertificate div .logo-content-sert div {
  display: flex;
  flex-direction: column;
}

.image-sertificate div .logo-content-sert div img:nth-child(1) {
  margin-top: -10px;
  width: 170px;
  height: 80px;
}

.image-sertificate div .logo-content-sert div img:nth-child(2) {
  margin-top: -40px;
  height: 70px;
  margin-left: 100px;
}

.image-sertificate div .logo-content-sert img {
  /*width: 150px;*/
  margin-left: 40px;
  height: 35px;
  z-index: 1000;
  background: transparent;
}

.image-sertificate div svg path {
  color: white;
}

.footer-div-sert {
  width: 600px;
  height: 298px;
  border-top-right-radius: 160px;
  border-bottom-right-radius: 160px;
  background: white;
}

.footer-div-sert div {
  margin-left: 20px;
  margin-top: 20px;
}

.footer-div-sert div .footer-logo {
  border: 1px solid #006aff;
  width: 100px;
  height: 50px;
  border-radius: 15px;
  text-align: center;
  align-items: center;
}

.footer-div-sert div .footer-logo img {
  width: 60px;
  height: 40px;
}

.footer-div-sert div h1 {
  color: white;
  font-family: Gilroy-Bold !important;
  background: #006aff;
  border-radius: 40px;
  width: 300px;
  line-height: 50px;
  letter-spacing: 0.06em;
  height: 50px;
  font-size: 38px;
  text-align: center;
  margin-left: 10px;
  margin-top: 20px;
}

.footer-div-sert div p {
  font-size: 35px;
  width: 400px;
  font-family: Samsung Sharp Sans !important;
  margin-left: 10px;
  margin-top: 10px;
  letter-spacing: 0.01em;
  color: #000000;
}

.footer-div-sert div input {
  width: 450px;
  height: 50px;
  border-radius: 10px;
  border: 0.5px solid #006aff;
  outline: none;
  font-size: 20px;
  padding-left: 10px;
  margin-left: 10px;
}

.footer-div-sert div .last-footer-logo {
  display: flex;
  width: 450px;
  margin-top: 5px;
  margin-left: 2px;
  align-items: center;
}

.footer-div-sert div .last-footer-logo p {
  color: #006aff;
  font-size: 10px;
}

.footer-div-sert div .last-footer-logo img {
  width: 50px;
  height: 50px;
}

.danger-block {
  color: red;
  font-family: Gilroy-Medium !important;
  /*font-weight: 600;*/
}

.fourth-block-course {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 20px;
}

.fourth-block-course .main-title-4block {
  color: #006aff;
  font-size: 22px;
  /*font-weight: 600;*/
  font-family: Gilroy-Medium !important;
}

.fourth-block-course .block4-1div {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.fourth-block-course .block4-1div p {
  font-size: 21px;
  /*font-weight: 600;*/
  font-family: Gilroy-Regular !important;
}

.fourth-block-course .block4-1div ul {
  list-style-type: disc;
  margin-left: 20px;
}

.fourth-block-course .block4-1div li {
  /*padding-top: 5px;*/
  font-family: Gilroy-Regular !important;
}

.fourth-block-course .block4-1div li::marker {
  color: #006aff;
  font-size: 23px;
}

.fourth-block-course .block4-2div {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.fourth-block-course .block4-2div p {
  font-size: 21px;
  /*font-weight: 600;*/
  font-family: Gilroy-Medium !important;
}

.fourth-block-course .block4-2div span {
  padding-top: 5px;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  font-family: Gilroy-Regular !important;
}

.fourth-block-course .block4-2div .bold-span {
  padding-top: 5px !important;
  font-family: Gilroy-Medium !important;
}

.fourth-block-course .block4-3div {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.fourth-block-course .block4-3div a {
  color: #006aff;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 20px;
  font-family: Gilroy-Medium !important;
}

.fourth-block-course .block4-3div span {
  font-size: 20px;
  font-family: Gilroy-Medium !important;
}

.fourth-block-course .block4-3div p {
  font-size: 16px;
  padding-top: 5px;
  font-family: Gilroy-Regular !important;
}

.fourth-block-course .block4-3div div {
  display: flex;
  padding-top: 10px;
}

.fourth-block-course .block4-3div div input {
  background: #f8f9fb;
  border: 1px solid #006aff;
  box-sizing: border-box;
  padding: 10px;
  outline: none;
}

.fourth-block-course .block4-3div div .value-div {
  width: 70%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-left: 20px !important;
}

.fourth-block-course .block4-3div div .copy-div {
  border: 1px solid #006aff;
  width: 13%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background: #e7f0ff;
  cursor: pointer;
}

.fourth-block-course .block4-3div div .copy-div svg {
  width: 15px;
  height: 15px;
}

.fourth-block-course .block4-3div div .copy-div p {
  font-size: 15px;
  font-weight: normal !important;
  padding-top: 0 !important;
  padding-left: 10px;
  font-family: Gilroy-Regular !important;
}

.footer-text {
  font-size: 15px !important;
  font-weight: normal !important;
  padding-top: 3px;
  font-family: Gilroy-Regular !important;
}

.fourth-block-course .block4-1div li {
  font-size: 15px;
}

@media (max-width: 1320px) {
  .add-course-content,
  .second-form,
  .fourth-block-course,
  .course-header-links {
    max-width: 98%;
  }

  .fourth-block-course .block4-3div div .copy-div {
    justify-content: center;
    width: 25%;
  }

  .course-header-links .header-links {
    padding-right: 6px;
    height: 55px;
    width: 230px;
  }

  .course-header-links .header-links .name-link {
    font-size: 13px;
  }

  .course-header-links .header-links .number-links span {
    font-size: 20px;
  }

  .course-header-links .header-links .number-links {
    padding: 5px 12px;
  }

  .last-block span {
    font-size: 15px;
    line-height: 24px;
  }

  .success-icon {
    width: 8px;
    height: 8px;
  }
}
@media (max-width: 1290px) {
  .choose-type-of-video div {
    width: 50%;
    margin-right: 0;
  }
  .block3-3div .text-div-sert .footer-sert {
    margin-top: 50px;
  }
  .choose-type-of-video div span {
    font-size: 14px;
  }
}
@media (max-width: 1200px) {
  .video-cover-div-course {
    padding: 20.5px 5px !important;
  }
  .video-cover-div-course label {
    font-size: 14px;
  }
  .third-block-course .image-sertificate {
    width: 550px;
  }
  .block3-3div .text-div-sert .footer-sert {
    margin-top: 80px;
  }
  .footer-div-sert div input {
    width: 360px;
  }

  .footer-div-sert div .last-footer-logo {
    width: 360px;
  }

  .block3-3div .text-div-sert p {
    font-size: 20px;
  }

  .footer-content-sert svg {
    margin-right: 20px;
    margin-left: 0;
  }

  .block3-3div .text-div-sert p {
    font-size: 20px;
  }

  .footer-div-sert div h1 {
    width: 280px;
    font-size: 28px;
  }

  .footer-div-sert div p {
    font-size: 34px;
  }
  .third-block-course .block3-1div div p {
    right: 43%;
  }
}
@media (max-width: 1120px) {
  .video-photo-block .photo-block .text-block .input-photo {
    margin-top: 10px;
  }

  .video-photo-block .photo-block .text-block p {
    line-height: 22px;
  }

  .add-course-content .course-main-title2 {
    font-size: 19px;
  }

  .success-icon {
    width: 12px;
    height: 8px;
    margin-left: 5px;
  }

  .course-header-links .header-links {
    margin-left: 5px;
  }
  .third-block-course .block3-1div div p {
    right: 35%;
  }
  .block3-3div .text-div-sert .footer-sert {
    margin-top: 60px;
  }
}
@media (max-width: 1024px) {
  .video-photo-block .photo-block .image-block {
    width: 60%;
  }
  .video-photo-block
    .photo-block
    .text-block
    .input-photo
    .video-cover-div-course {
    width: 40%;
  }
  .video-photo-block .photo-block .text-block input {
    width: 60%;
  }
}
@media (max-width: 980px) {
  .choose-type-of-video div input {
    width: 30px !important;
    height: 15px !important;
  }
  .add-course-content .name-course-block div {
    margin-bottom: 5px;
  }
  .block-next-selects .selects-block {
    margin-bottom: 20px;
  }
  .video-photo-block
    .photo-block
    .text-block
    .input-photo
    .video-cover-div-course
    label {
    font-size: 15px;
  }

  .fourth-block-course .block4-3div div .copy-div {
    padding-left: 5px;
    line-height: normal;
  }

  .fourth-block-course .block4-3div div .copy-div {
    width: 20%;
  }

  .third-block-course .image-sertificate {
    width: 500px;
  }

  .footer-div-sert div input {
    width: 310px;
  }

  .footer-div-sert div .last-footer-logo {
    width: 310px;
  }

  .block3-3div .text-div-sert p {
    font-size: 18px;
  }

  .footer-div-sert div h1 {
    width: 240px;
    font-size: 28px;
  }

  .footer-div-sert div p {
    font-size: 32px;
  }

  .footer-content-sert svg {
    margin-right: 20px;
    margin-left: 0;
  }
}

@media (max-width: 910px) {
  .video-photo-block
    .photo-block
    .text-block
    .input-photo
    .video-cover-div-course
    label {
    font-size: 13px;
  }

  .video-photo-block .photo-block .text-block .input-photo {
    margin-top: 20px;
  }

  .video-photo-block .photo-block .text-block p {
    font-size: 15px;
    line-height: 20px;
  }

  .course-header-links .header-links .name-link {
    line-height: 16px;
  }
  .third-block-course .block3-1div div p {
    right: 25%;
  }
}

@media (max-width: 840px) {
  .video-photo-block
    .photo-block
    .text-block
    .input-photo
    .video-cover-div-course
    label {
    font-size: 11px;
  }

  .course-header-links .header-links .number-links {
    padding: 1px 9px;
  }

  .course-header-links .header-links .number-links span {
    font-size: 16px;
  }
}

@media (max-width: 769px) {
  .video-photo-block .photo-block {
    flex-direction: column;
  }

  .course-header-links {
    padding-top: 110px;
  }

  .video-photo-block .photo-block .image-block {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  /* 
  .link-input {
    width: 75%;
  } */
  .video-photo-block .photo-block .image-block img {
    width: 70%;
    height: 150px;
  }

  .video-photo-block .photo-block .text-block {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .video-photo-block .photo-block .text-block .input-photo {
    width: 100%;
    margin-right: 0;
    margin-top: 15px;
  }

  .video-photo-block .photo-block .text-block p {
    text-align: center;
    line-height: 24px;
    padding-top: 5px;
  }

  .fourth-block-course .block4-3div div .copy-div p {
    font-size: 13px;
  }

  .third-block-course .image-sertificate {
    height: 300px;
    width: 450px;
  }

  .image-sertificate div .logo-content-sert {
    height: 65px;
  }

  .image-sertificate div .logo-content-sert div img:nth-child(1) {
    width: 120px;
  }

  .image-sertificate div .logo-content-sert div img:nth-child(2) {
    margin-left: 85px;
    width: 75px;
  }

  .footer-div-sert {
    width: 370px;
    height: 232px;
  }

  .footer-content-sert svg {
    margin-top: 177px;
  }

  .image-sertificate div .logo-content-sert img {
    height: 29px;
  }

  .footer-div-sert div .footer-logo {
    width: 59px;
    height: 35px;
  }

  .footer-div-sert div {
    margin-top: 12px;
  }

  .footer-div-sert div .footer-logo img {
    width: 45px;
    height: 27px;
  }

  .footer-div-sert div h1 {
    width: 188px;
    font-size: 22px;
    line-height: 35px;
    height: 35px;
    margin-top: 15px;
  }

  .footer-div-sert div p {
    font-size: 26px;
  }

  .footer-div-sert div input {
    width: 258px;
    height: 38px;
  }

  .footer-div-sert div .last-footer-logo {
    width: 260px;
    /*text-align: center;*/
    align-items: center;
  }

  .block3-3div .text-div-sert .top-sert {
    margin-top: -5px;
  }

  .block3-3div .text-div-sert p {
    font-size: 15px;
  }

  .block3-3div .text-div-sert div img {
    padding-left: 30px;
    height: 16px;
    margin-top: 10px;
  }

  .block3-3div .text-div-sert button {
    margin-top: 15px;
    width: 100px;
    font-size: 11px;
  }

  .block3-3div .text-div-sert .footer-sert {
    margin-top: 45px;
  }

  .footer-div-sert div .last-footer-logo img {
    width: 45px;
    height: 45px;
  }
  .third-block-course .block3-1div div p {
    right: 25%;
  }
}

@media (max-width: 670px) {
  .course-header-links .header-links .name-link {
    font-size: 13px;
  }

  .course-header-links .header-links .number-links {
    padding: 0 8px;
  }

  .course-header-links .header-links .number-links span {
    font-size: 14px;
  }

  .success-icon {
    width: 10px;
    height: 6px;
  }

  .block3-3div .text-div-sert div img {
    padding-left: 13px;
    height: 10px;
  }

  .block3-3div .text-div-sert p {
    font-size: 14px;
  }

  .block3-3div .text-div-sert button {
    margin-top: 15px;
    width: 64px;
    font-size: 9px;
    height: 30px;
    border-radius: 10px;
  }
  .third-block-course .block3-1div div p {
    right: 17%;
  }
}

@media (max-width: 600px) {
  .add-course-content {
    width: unset;
  }

  .video-photo-block .photo-block .image-block img {
    width: unset;
  }
  .course-header-links .header-links .number-links {
    display: none;
  }
  .course-header-links .header-links {
    padding: 0;
  }

  .course-header-links .header-links .name-link {
    font-size: 12px;
    margin-left: 0;
  }
  .third-block-course .image-sertificate {
    height: 240px;
    width: 350px;
  }

  .image-sertificate div .logo-content-sert {
    height: 40px;
  }

  .image-sertificate div .logo-content-sert img {
    height: 18px;
  }

  .image-sertificate div .logo-content-sert div img:nth-child(1) {
    z-index: 1;
    height: 50px;
    width: 80px;
  }
  .third-block-course .block3-1div div p {
    right: 12%;
  }
  .image-sertificate div .logo-content-sert div img:nth-child(2) {
    margin-top: -20px;
    height: 40px;
    margin-left: 70px;
    width: 50px;
  }

  .third-block-course .block3-2div label {
    z-index: 2;
  }

  .footer-div-sert {
    width: 295px;
    height: 198px;
  }

  .footer-div-sert div .footer-logo {
    width: 40px;
    height: 28px;
    border-radius: 8px;
  }

  .footer-div-sert div .footer-logo img {
    width: 33px;
    height: 22px;
  }

  .footer-div-sert div {
    margin-top: 8px;
  }

  .footer-div-sert div h1 {
    width: 135px;
    font-size: 16px;
    line-height: 27px;
    height: 27px;
    margin-top: 20px;
  }

  .footer-div-sert div p {
    font-size: 20px;
    margin-top: 14px;
  }

  .footer-div-sert div input {
    width: 190px;
    height: 25px;
    border-radius: 5px;
  }

  .footer-div-sert div .last-footer-logo {
    width: 190px;
    margin-top: 10px;
  }

  .footer-div-sert div .last-footer-logo img {
    width: 30px;
    height: 30px;
  }

  .footer-div-sert div .last-footer-logo p {
    font-size: 8px;
  }

  .block3-3div .text-div-sert {
    margin-top: 62px;
  }

  .block3-3div .text-div-sert p {
    font-size: 13px;
  }

  .block3-3div .text-div-sert div img {
    height: 9px;
  }

  .block3-3div .text-div-sert button {
    margin-left: 10px;
  }

  .footer-content-sert svg {
    margin-top: 145px;
  }
}

@media (max-width: 514px) {
  .video-photo-block {
    margin-top: 0;
  }
  .video-photo-block
    .photo-block
    .text-block
    .input-photo
    .video-cover-div-course
    label {
    font-size: 9px;
  }
  .video-photo-block
    .photo-block
    .text-block
    .input-photo
    .video-cover-div-course {
    padding: 7px;
  }
  .video-photo-block .photo-block .text-block input {
    font-size: 15px;
  }

  .firstBlock {
    flex-direction: column;
  }
  .first,
  .video-photo-block,
  .second-form {
    width: 100%;
  }
  .last-block div {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .choose-type-of-video div span {
    font-size: 12px;
    text-align: center;
  }
  .choose-type-of-video div input {
    width: 20px !important;
    height: 10px !important;
  }
  .add-course-content .block-next-selects {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .add-course-content .block-next-selects .selects-block {
    width: 100%;
  }

  .add-course-content .block-next-selects .selects-block select {
    width: 100%;
  }

  .last-block span {
    line-height: 24px;
  }

  .fourth-block-course .block4-3div div .copy-div p {
    font-size: 11px;
  }

  .third-block-course .image-sertificate {
    width: 300px;
    height: 200px;
  }

  .footer-div-sert {
    width: 249px;
    height: 158px;
  }
  .footer-div-sert div .footer-logo img {
    width: 30px;
    height: 20px;
  }
  .footer-div-sert div h1 {
    width: 119px;
    font-size: 15px;
    line-height: 22px;
    height: 22px;
    margin-top: 9px;
  }
  .footer-div-sert div p {
    font-size: 18px;
    margin-top: 7px;
  }
  .footer-div-sert div input {
    width: 174px;
    height: 22px;
    border-radius: 4px;
  }
  .footer-div-sert div .last-footer-logo {
    width: 168px;
    margin-top: 6px;
  }
  .footer-content-sert svg {
    margin-top: 110px;
  }
}
