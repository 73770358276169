.question {
    width: 100%;
    padding: 0 40px;
    height: 100%;
}

.question .btn-question {
    text-align: end;
    align-items: end;
    justify-content: end;
    display: flex;
    width: 100%;
}

.question .btn-question button {
   margin-top: 10px;
   padding: 10px;
   border-radius: 10px;
   background: #006aff;
   color: white;
   border: none;
}

.wrapper-question {
    display: flex;
    height: 100%;
    /*overflow: scroll;*/
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.question-container {
    width: 100%;
    height: 248px;
    margin-top: 20px;
    border: 0.5px dashed #5B5B5B;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.question-add {
    width: 98px;
    height: 98px;
    border-radius: 50%;
    background: #006AFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.question-container__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

@media (max-width: 780px) {
    .question {
        margin-top: 100px;
    }

}