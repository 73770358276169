/*@font-face {*/
/*  font-family:'Gilroy-Medium' ;*/
/*  src: url(../fonts/Gilroy-Medium.ttf);*/
/*}*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1250px;
  margin: 0 auto;
}

.mainSignUpPage2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 680px;
  margin: 0 auto;
  padding-bottom: 200px;
}
.signUpPage-input-item2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signUpPage-inner-item2 {
  width: 398px;
  height: 80px;
  padding-left: 35px;
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  color: rgba(17, 17, 17, 0.36);
  background: rgba(17, 17, 17, 0.02);
  border-radius: 24px;
  border: none;
  outline: none;
  margin-top: 20px;
}

.sectionLoginPage + .footer {
  display: none!important;
}
.sectionLoginPage ~ .footer_vector {
  display: none!important;
}
.sectionSignUpPage + .footer {
  display: none!important;
}
.sectionSignUpPage ~ .footer_vector {
  display: none!important;
}
.sectionSignUpPage2 + .footer {
  display: none!important;
}
.sectionSignUpPage2 ~ .footer_vector {
  display: none!important;
}
.sectionSignUpPage3 + .footer {
  display: none!important;
}
.sectionSignUpPage3 ~ .footer_vector {
  display: none!important;
}
.ParolUnut_1_block + .footer {
  display: none!important;
}
.ParolUnut_1_block ~ .footer_vector {
  display: none!important;
}
.ParolUnut_2_block  + .footer {
  display: none!important;
}
.ParolUnut_2_block  ~ .footer_vector {
  display: none!important;
}
.ParolUnut_3_block  + .footer {
  display: none!important;
}
.ParolUnut_3_block  ~ .footer_vector {
  display: none!important;
}
.signUpPage-btn-inner2 {
  width: 398px;
  height: 80px;
  padding: 27px 125px 27px 125px;
  background: #e7f0ff;
  border-radius: 24px;
  border: none;
  margin-top: 24px;
}
.signUpPage-btn-inner2 a {
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  color: #006aff;
  text-decoration: none;
}
.bottom-space {
  margin-bottom: 0;
  margin-top: 10px;
}
.signUpPage-text-item2 {
  margin-top: 34px;
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(17, 17, 17, 0.36);
  margin-left: 15px;
}
.signUpPage-text-item2 a {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: #006aff;
  text-decoration: none;
}
@media (max-width:768px) {
  .mainSignUpPage2 {
    padding-bottom: 100px;
  }
  .signUpPage-inner-item2 {
    margin-top: 100px;
  }
  .footer {
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 375px) {
  .signUpPage-inner-item2 {
    width: 350px;
    font-size: 20px;
  }
  .signUpPage-btn-inner2 {
    width: 350px;
    padding: 25px 110px 25px 110px;
  }
  .signUpPage-btn-inner2 a {
    font-size: 21px;
  }
  .signUpPage-text-item2 {
    font-size: 16px;
  }
  .signUpPage-text-item2 a {
    font-size: 16px;
  }
}
@media (max-width:320px) {
  .signUpPage-btn-inner2 {
    width: 300px;
    padding: 25px 80px 25px 80px;
  }
  .signUpPage-inner-item2 {
    width: 300px;
    font-size: 18px;
  }
  .signUpPage-btn-inner2 a {
    font-size: 20px;
  }
  .signUpPage-text-item2 {
    font-size: 14px;
  }
  .signUpPage-text-item2 a {
    font-size: 14px;
  }
}
