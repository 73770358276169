.quiz-result-container {
	max-width: 1000px;
	margin: 0 auto;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quiz-result-title {
	font-size: 24px;
	text-align: center;
	margin-bottom: 20px;
	color: #333;
}

.quiz-result-list {
	list-style-type: none;
	padding: 0;
}

.quiz-result-item {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 1px solid #ddd;
}

.quiz-result-item:last-child {
	border-bottom: none;
}

.quiz-question {
	font-size: 18px;
	color: #555;
}

.quiz-result-icon {
	font-size: 24px;
}

.correct {
	color: green;
}

.incorrect {
	color: red;
}

.result-page {
	width: 900px;
	margin: 0 auto;
	border: 1px solid #000;
	padding: 20px;
	font-family: Arial, sans-serif;
	background-color: #f8f9fa;
}

.result-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #000;
	padding-bottom: 10px;
	margin-bottom: 20px;
}

.header-left,
.header-right {
	width: 20%;
}

.header-center {
	width: 60%;
	text-align: center;
}

.dtm-logo {
	width: 100%;
}

.result-header h1 {
	font-size: 24px;
	color: #d9534f;
	margin: 0;
}

.header-info p {
	font-size: 14px;
	text-align: center;
}

.test-info {
	font-size: 14px;
	text-align: center;
	margin-bottom: 20px;
}

.test-info p {
	margin: 5px 0;
}

.answer-sheet {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.answer-section h3,
.score-section h3 {
	font-size: 16px;
	text-align: center;
	margin-bottom: 10px;
}

.answer-section table,
.score-section table {
	width: 100%;
	border-collapse: collapse;
}

.answer-section th,
.answer-section td,
.score-section td {
	border: 1px solid #000;
	padding: 8px;
	text-align: center;
}

.answer-section td.correct {
	background-color: #d4edda;
}

.answer-section td.incorrect {
	background-color: #f8d7da;
}

.result-footer {
	text-align: center;
	margin-top: 20px;
	font-size: 14px;
}

.signatures {
	border-top: 1px solid #000;
	display: inline-block;
	width: 200px;
	margin-top: 10px;
}
