.select {
   position: relative;
}


.select .select-btn {
   width: 288px;
   height: 51px;
   padding: 0 24px;
   background: #006AFF;
   box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
   font-weight: 500;
   font-size: 13px;
   line-height: 15px;
   letter-spacing: 0.05em;
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
   color: #fff;
}

.select .select-container {
   position: absolute;
   top: 80%;
   overflow-y: auto;
   padding: 10px;
   background: #fff;
   box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
   color: black;
   width: 100%;
   max-height: 250px;
   border-radius: 10px;
   cursor: pointer;
   z-index: 10000;
}

.left {
   border-radius: 10px 0 0 10px;
}

.right {
   border-radius: 0 10px 10px 0;
}

.select .select-container .select-item {
   padding: 10px 20px;
}

@media (max-width:654px) {
   .select-btn {
      width: 180px;
   }
}